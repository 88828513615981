import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import '../../../assets/market/market.css';
import { scrollToBottom, checkIsValid, shouldDispatchQuestion, getLastQuestion, getUpdatedResponseBasedOnQtype } from '../../utils/Common';
import { getQuestions, saveQnaQuestion, getChatResponse } from '../../services/apiService';
import ChatLoader from "../../services/chat-loader";
import averageEmojiIcon from '../../../assets/emoji/average_emoji_icon.svg';
import fairEmojiIcon from '../../../assets/emoji/fair_emoji_icon.svg';
import positiveEmojiIcon from '../../../assets/emoji/positive_emoji_icon.svg';
import ShowOffer from "../market/show-offer";
import { loginData } from '../../../slices/loginSlice';
import { addQuestion, clearMatchQuestionList } from '../../../slices/matchSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';



// Agent Mira imports
import '../../agent-mira/chat.css'; 
import DOMPurify from 'dompurify';
import ChatInput from "../chat-input";
import StickyCard from "../sticky-card";
import PropertyTabs from "../tabs";
// End of imports

const emojiImages = {
    "face-emoji-ok":fairEmojiIcon,
    "face-emoji-good": averageEmojiIcon,
    "face-emoji-max":positiveEmojiIcon
}

const MatchChat = ({propertyMatchQuestions, setPropertyMatchQuestions, pageName }) => {
    const bottomRef = useRef(null);
    const propertyId = useSelector((state) => state.propertySlice.propertyId);
    const [refreshOfferPrice, setRefreshOfferPrice] = useState(null);
    const userDetails = useSelector(loginData);
    const dispatch = useDispatch();
    const hasFetched = useRef(false);



    // Agent mira chat variables
    const [userInput, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    // End of variables

    useEffect(() => {
        
        if (propertyMatchQuestions.length > 0) {
            if(!hasFetched.current && getLastQuestion(propertyMatchQuestions)){
                fetchOfferDetails(true);
                hasFetched.current = true;
            }
            scrollToBottom(bottomRef);
        }
    }, [propertyMatchQuestions, userDetails]);

    useEffect(() => {
        // Handle property market questions
        if (propertyMatchQuestions) {
            dispatch(clearMatchQuestionList());
            dispatch(addQuestion(propertyMatchQuestions));
        }
    }, [propertyMatchQuestions, dispatch]);

    const fetchOfferDetails = async (payload) => {
        setLoading(false);
        const dataToSend = { isHistory: payload, timestamp: new Date().toLocaleTimeString() };
        // Send data to the child component
        setRefreshOfferPrice(dataToSend);
    };

    const selectOption = async (question, option, checkedStatus) => {
        const updatedMatchQuestions = getUpdatedResponseBasedOnQtype(propertyMatchQuestions, question, option, checkedStatus);
        setPropertyMatchQuestions(updatedMatchQuestions);

        const checkedOptions = getCheckedResponses(updatedMatchQuestions, question);
        const updatedWithCheckedOptions = updatedMatchQuestions.map(ques =>
            ques.question_id === question.question_id
                ? {
                    ...ques,
                    response: checkedOptions,
                    isSaved: true,
                }
                : ques
        );
        
        if(!question.hasOwnProperty("response")){
            setLoading(true);
        }

        const response = await saveQnaQuestion({ question_id: question.question_id, response: checkedOptions }, pageName, propertyId);
        
        if (response.code === 200) {
            if((question.isLast === true || (question.hasOwnProperty("response") && getLastQuestion(updatedWithCheckedOptions)))){
                fetchOfferDetails(false);
            }
            // To save a key named isSaved so that frontend knows this question is saved.
            setPropertyMatchQuestions(updatedWithCheckedOptions);

            const questionResponse = await getQuestions(propertyId, pageName);
            if(questionResponse.code === 200){
                setLoading(false);
                if (shouldDispatchQuestion(questionResponse.response, updatedMatchQuestions)) {
                    setPropertyMatchQuestions([...updatedWithCheckedOptions, questionResponse.response]);
                }
            }
            else {
                setLoading(false);
            }
        }
        else {
            setLoading(false);
        }
    }

    const getCheckedResponses = (allQuestions, question) => {
        const selectedQuestion = allQuestions.find(ques => ques.question_id === question.question_id);
        const checkedOptions = selectedQuestion.response_options.filter(option => option.isChecked === true);
        const payload = []
        checkedOptions.forEach(option => {
            payload.push(option.option_id)
        })
        return payload;
    }

    const saveDefaultResponse = async (questionId) => {
        setLoading(true);
        try {
            // Save a default response
            const response = await saveQnaQuestion({ question_id: questionId, response: ["1"] }, pageName, propertyId);
            if (response.code === 200) {
                // Fetch updated questions after saving
                const apiResponse = await getQuestions(propertyId, pageName);
                if (apiResponse.code === 200) {
                    if (shouldDispatchQuestion(apiResponse.response, propertyMatchQuestions)) {
                        setPropertyMatchQuestions(prevQuestions => [
                            ...prevQuestions,
                            apiResponse.response // Add the new question response to state
                        ]);
                    }

                    if (apiResponse.response.isLast === true) {
                        setLoading(false);
                        await saveDefaultResponse(apiResponse.response.question_id);
                        
                        setTimeout(async () => {
                            fetchOfferDetails(false);
                        }, 8000);
                    }
                    scrollToBottom(bottomRef);
                }
                else {
                }
            }
        }
        catch (error) {
        }
        setLoading(false);
    }

    // ===============================================================================================================
    // ===============================================================================================================
    // ================================Agent Mira Chat Functions =====================================================
    // ===============================================================================================================
    // ===============================================================================================================

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && userInput.trim() !== '') {
            saveChatAndGetResponse('user', userInput);
        }
    };

    const getChat = () => {
        if (userInput.trim() !== '') {
            saveChatAndGetResponse('user', userInput);
        }
    };

    const getChatSuggestions = (suggestion) => {
        saveChatAndGetResponse('user', suggestion);
    };

    const handleChatResponse = (responseMessage) => {
        const updateChat = { sender: "mira", message: responseMessage, chat: true };
        setPropertyMatchQuestions(prevState => [...prevState, updateChat]);
    };
    
    const saveChatAndGetResponse = async (type, message) => {
        const errorMessage = "There is some issue with accessing our AI engine, can you please ask your question again.";
        setInput('');
        const newMessage = { sender: type, message: message, chat: true };
        setPropertyMatchQuestions(prevState => [...prevState, newMessage]);
    
        try {
            setLoading(true);
            const chat_response = await getChatResponse({ "user_str": message, "page": "property_match" }, propertyId);
    
            if (chat_response.code === 200) {
                if (chat_response.response.mira_chat_response) {
                    handleChatResponse(chat_response.response.mira_chat_response);
                } 
                else {
                    handleChatResponse(errorMessage);
                }
            } 
            else {
                handleChatResponse(errorMessage);
            }
        } 
        catch (error) {
            const updateChat = { sender: "mira", message: errorMessage, chat: true };
            setPropertyMatchQuestions(prevState => [...prevState, updateChat]);
        } 
        finally {
            // setLoading(false);
        }

        if (checkIsValid(propertyMatchQuestions)) {
            const getLatestQuestion = await getQuestions(propertyId, pageName);
            if(getLatestQuestion.code === 200){
                setPropertyMatchQuestions(prevState => {
                    const matchQues = [...prevState];
                    const questionsWithBothKeys = matchQues.filter(question => question.question_id === getLatestQuestion.response.question_id);
            
                    if (questionsWithBothKeys.length > 0) {
                        // Remove existing question with the same question_id
                        const index = matchQues.findIndex(question => question.question_id === getLatestQuestion.response.question_id);
                        matchQues.splice(index, 1); 
                    }
            
                    // Add new response to the end of the array
                    matchQues.push(getLatestQuestion.response);
            
                    return matchQues;
                });
                setLoading(false);
            }
            else {
                setLoading(false);
            }
        }
        else {
            setLoading(false);
        }
    };

    return (
        <>  
            <div className="container_main">
                <div className="part right-section">
                    <div className="chat-container">
                        <div className="chat-header fixed-button-top">
                            <PropertyTabs />
                        </div>

                        <div className="chat-window pt-0">
                            <div className="content mt-5">
                                {propertyMatchQuestions && propertyMatchQuestions.length > 0 &&
                                    <>
                                        {propertyMatchQuestions.map((ques, index) => (
                                            <>
                                                {ques?.chat === true ? 
                                                    <>
                                                        {/* Agent mira chat list */}
                                                        {ques?.sender === 'mira' &&
                                                            <div className="message-holder mira-chat ml-85 mr-85" id={index} data-test-id={`mira-response-${index}`} key={index}>
                                                                <div className="message-bubble">
                                                                    
                                                                    <div className={`label ${index === 0 ? 'd-flex font-weight-bold' : 'd-none'}`}>
                                                                        Agent Mira
                                                                    </div>
                                                                    <div className="message-text">
                                                                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ques.message) }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                        {ques?.sender === 'user' &&
                                                            <div className="message-holder user-block ml-85" id={index} data-test-id={`user-response-${index}`} data-content={`${atob(userDetails?.firstName).charAt(0).toUpperCase()}${atob(userDetails?.lastName).charAt(0).toUpperCase()}`} key={index}>
                                                                <div className="message-bubble reply-bubble">
                                                                    
                                                                    <div className="message-text d-flex js-start">
                                                                        <div className="mb-2">
                                                                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ques.message) }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {/* Agent Mira chat ends */}
                                                    </> 
                                                :  
                                                    <>
                                                        {ques?.question_type && 
                                                            <>
                                                                <div className="message-holder mira-chat ml-85 mr-85" id={ques?.question_id} data-test-id={`question-block-${ques?.question_id}`} key={ques?.question_id}>
                                                                    <div className="message-bubble">
                                                                        <div className={`label ${index === 0 ? 'd-flex font-weight-bold' : 'd-none'}`}>
                                                                            Agent Mira
                                                                        </div>
                                                                        <div className="message-text">
                                                                            <>
                                                                                {index === 0 ? (
                                                                                <div className="w-100 d-flex"><FontAwesomeIcon icon={faInfoCircle} className="me-2 font-size-xxl" />
                                                                                <span>{ques?.question_text}</span></div>

                                                                                ) : (
                                                                                    <span>{ques?.question_text}</span>
                                                                                )}
                                                                            </>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {ques.question_type !== "text-only" &&
                                                                    <div className="message-holder sub-card ml-85 mr-85">
                                                                        <div className="message-bubble bg-none p-0 mt-2">
                                                                            {ques.question_type === "single-select" && (
                                                                                <div className="chat_row">
                                                                                    <div className="chat_mira_child">
                                                                                        {ques?.response_options?.length > 0 && (
                                                                                            <div className={` ${loading ? 'disable' : ''}`}>
                                                                                                <div className="commute-checkbox-parent">
                                                                                                    {ques.response_options.map((option) => (
                                                                                                        <>
                                                                                                            {(option?.isChecked === true) ?
                                                                                                                <div className="commute-checkbox" id={option?.option_id} data-test-id={`option-checked${option.option_id}`} onClick={() =>  selectOption(ques, option, false)}>
                                                                                                                    <div className="check">
                                                                                                                        <img className="icon" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/preference/screen1/icon.svg`} />
                                                                                                                    </div>
                                                                                                                    <div className="commute-friendly">{option?.option_text}</div>
                                                                                                                </div>
                                                                                                            :
                                                                                                                <div className="checkbox-container" id={option?.option_id} data-test-id={`option-unchecked${option.option_id}`} onClick={() =>  selectOption(ques, option, true)}>
                                                                                                                    <div className="checkbox">
                                                                                                                        <div className="checkbox-base"></div>
                                                                                                                    </div>
                                                                                                                    <div className="kid-friendly">{option?.option_text}</div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </>
                                                                                                    ))}
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            )}

                                                                            {ques.question_type === "single-select-emoji" && (
                                                                                <div className="chat_row">
                                                                                    <div className="chat_mira_child">
                                                                                        {ques?.response_options?.length > 0 && (
                                                                                            <div className={` ${loading ? 'disable' : ''}`}>
                                                                                                <div className="commute-checkbox-parent">
                                                                                                    {ques.response_options.map((option) => (
                                                                                                        <>
                                                                                                            {(option?.isChecked === true) ?
                                                                                                                <div className="commute-checkbox" id={option?.option_id} data-test-id={`option-checked${option.option_id}`} onClick={() =>  selectOption(ques, option, false)}>
                                                                                                                    {option?.option_id !== "" && <img width="20" loading="lazy" alt="" src={emojiImages[option?.option_id]} /> }
                                                                                                                    <div className="commute-friendly">{option?.option_text}</div>
                                                                                                                </div>
                                                                                                            :
                                                                                                                <div className="checkbox-container" id={option?.option_id} data-test-id={`option-unchecked${option.option_id}`} onClick={() =>  selectOption(ques, option, true)}>
                                                                                                                    {option?.option_id !== "" && <img width="20" loading="lazy" alt="" src={emojiImages[option?.option_id]} /> }
                                                                                                                    <div className="kid-friendly">{option?.option_text}</div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </>
                                                                                                    ))}
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            )}

                                                                            {ques.question_type === "multiple-select" && (
                                                                                <div className="chat_row">
                                                                                <div className="chat_mira_child">
                                                                                    {ques?.response_options?.length > 0 && (
                                                                                        <div className={` ${loading ? 'disable' : ''}`}>
                                                                                            <div className="commute-checkbox-parent">
                                                                                                {ques.response_options.map((option) => (
                                                                                                    <>
                                                                                                        {(option?.isChecked === true) ?
                                                                                                            <div className="commute-checkbox" id={option?.option_id} data-test-id={`option-checked${option.option_id}`} onClick={() => selectOption(ques, option, false)}>
                                                                                                                <div className="check">
                                                                                                                    <img className="icon" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/preference/screen1/icon.svg`} />
                                                                                                                </div>
                                                                                                                <div className="commute-friendly">{option?.option_text}</div>
                                                                                                            </div>
                                                                                                            :
                                                                                                            <div className="checkbox-container" id={option?.option_id} data-test-id={`option-unchecked${option.option_id}`} onClick={() => selectOption(ques, option, true)}>
                                                                                                                <div className="checkbox">
                                                                                                                    <div className="checkbox-base"></div>
                                                                                                                </div>
                                                                                                                <div className="kid-friendly">{option?.option_text}</div>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </>
                                                                                                ))}
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                        }

                                                        {!loading && (index === (propertyMatchQuestions.length - 1)) && !ques?.isSaved && ques?.isLast === false && ques?.question_type !== "single-select" && ques?.question_type !== 'single-select-emoji' && 
                                                            <>
                                                                <div id="next-question" data-test-id={`get-next-question${ques?.question_id}`} className="d-flex js-end mt-3" onClick={() => saveDefaultResponse(ques?.question_id)}>
                                                                    <button className="button-secondary mw-10em">Next</button>
                                                                </div>
                                                            </>
                                                        }

                                                        {ques?.isLast === true && (ques?.response || ques?.isSaved) &&
                                                            <>
                                                                <ShowOffer index={index} pageName={pageName}  dataFromParent={refreshOfferPrice} />
                                                            </>
                                                        }
                                                    </>
                                                }

                                                {(index === (propertyMatchQuestions.length - 1)) && !loading && propertyMatchQuestions?.length > 2 && <div className="mt-btm"></div> }
                                            </>
                                        ))}
                                    </>
                                }
                            
                                {/* If chat not loaded yet */}
                                {(loading || propertyMatchQuestions?.length < 2) &&
                                    <div className="message-holder mira-chat ml-85 mr-85">
                                        <div className="message-bubble">
                                            <div className={`label ${propertyMatchQuestions?.length === 0  ? 'd-flex font-weight-bold' : 'd-none'}`}>
                                                Agent Mira
                                            </div>
                                            <div className="message-text d-flex js-start">
                                                <ChatLoader />
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div ref={bottomRef} />
                            </div>
                        </div>

                        <div className="fixed-button mt-3">
                            <ChatInput userInput={userInput} handleInputChange={handleInputChange} handleKeyDown={handleKeyDown} getChat={getChat} getChatSuggestions={getChatSuggestions} loading={loading} pageName={pageName} />
                        </div>
                    </div>
                </div>

                <div className="part left-section">
                    <StickyCard />
                </div>
            </div>
        </>
    )
}

export default MatchChat;