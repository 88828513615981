import React, { useState, useEffect } from 'react';
import './progressBar.css';

const ProgressBarComponent = ({ minValue, maxValue, step, startingValue, prefix, ques, onData }) => {
    const sliderStep = 1000000;
    const getInitialValue = () => {
        if (ques && ques?.response && ques?.response?.length > 0) {
            let initialValue = ques.response[0];
            if (typeof initialValue === 'string') {
                initialValue = initialValue.replace(/,/g, '');
            }
            return Number(initialValue);
        }
        return startingValue !== null ? startingValue : minValue;
    };

    const [value, setValue] = useState(getInitialValue());
    const [isChecked, setIsChecked] = useState(true);

    const handleCheckboxChange = () => {
        setIsChecked((prevState) => !prevState);
    };

    const handleSliderChange = (e) => {
        setValue(Number(e.target.value));
    };

    const handleInputChange = (e) => {
        let newValue = e.target.value.replace(/[^0-9]/g, '');
        setValue(newValue === '' ? '' : Number(newValue));
    };

    const handleInputBlur = (e) => {
        let newValue = Number(e.target.value.replace(/[^0-9]/g, ''));
        if (newValue < minValue) newValue = minValue;
        if (newValue > maxValue) newValue = maxValue;
        setValue(newValue);
    };

    const progressWidth = value !== '' ? ((value - minValue) / (maxValue - minValue)) * 100 : 0;

    useEffect(() => {
        document.documentElement.style.setProperty('--slider-progress', `${progressWidth}%`);
    }, [progressWidth]);

    useEffect(() => {
        onData(value);
    }, [value, onData]);

    const ticks = [];

    // Generate tick marks based on the step value
    for (let i = minValue; i <= maxValue; i += (sliderStep)) {
        ticks.push(
            <div key={i} className="tick pointer" style={{ left: `${((i - minValue) / (maxValue - minValue)) * 100}%` }} onClick={() => setValue(i)} title={`$${i}`}>
            </div>
        );
    }

    return (
        <>
            <div className="prs_frame-parent6 mt-2">
                <div className="prs_frame-wrapper9">
                    <div className="prs_select-an-amount-wrapper">
                        <div className="prs_select-an-amount">Select an amount</div>
                    </div>
                </div>
                <div className="prs_frame-parent7">
                    <div className="prs_frame-wrapper10 mt-1">
                        <span className={`input ${prefix ? 'prefix' : ''}`}>
                            {prefix}
                        </span>
                        <input
                            className={`prs_frame-input ${prefix ? 'with-prefix' : ''}`}
                            placeholder="$300,000"
                            type="text"
                            value={value !== '' ? value.toLocaleString() : ''}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            data-test-id="amount-input"
                        />
                    </div>
                </div>
            </div>

            <div className="prs_slider-shapes-wrapper">
                <div className="slider-container">
                    <div className="slider-wrapper">
                        <div className="ticks-container w-100">
                            <div className='ticks-holder'>
                                {ticks}
                            </div>
                        </div>
                        <input
                            type="range"
                            className="slider"
                            min={minValue}
                            max={maxValue}
                            step={step}
                            value={value !== '' ? value : minValue}
                            onChange={handleSliderChange}
                            data-test-id="range-slider"
                        />
                    </div>
                    <div className="slider-labels">
                        <span>{ques?.minPrice}</span>
                        <span>{ques?.maxPrice}</span>
                    </div>
                </div>
            </div>

            <div className="prs_container">
                <div className="">
                    <input type="checkbox" className="form-check-input mt-0 circle mb-5" id="flexible" data-test-id="flexible-checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                    <label className="prs_im-slightly-flexible ms-2" htmlFor="flexible">I’m a little flexible on my budget</label>
                </div>
            </div>
        </>
    );
};

export default ProgressBarComponent;
