import React, { useState, useContext, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Modal from '../utils/Modal';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUser, faHome, faLock } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../utils/AuthContext';
import { userLogout } from '../services/apiService';
import { LoaderContext } from '../services/LoaderContext';
import { getUserListings, getPropertyListings, updateOfferManually } from '../services/apiService';
import { loginData } from '../../slices/loginSlice';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ChangePassword from '../admin-management/change-password';

const cs_width = "30%";
const admin_modal_width = "45%";

const Header = () => {
    const navigate = useNavigate();
    const { token, logout } = useAuth();
    const location = useLocation();
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [showModal, setShowModal] = useState(false);
    const [showAdminModal, setShowAdminModal] = useState(false);
    const [userList, setUserList] = useState([]);
    const [propertyList, setPropertyList] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedProperty, setSelectedProperty] = useState('');
    const [isChecked, setIsChecked] = useState('');
    const [offerPrice, setOfferPrice] = useState('');
    const [errorMessage, setErrorMessage] = useState('No user list found.');
    const [existingPrice, setExistingPrice] = useState({});
    const userDetails = useSelector(loginData);
    const [changeModal, setChangeModal] = useState(false);

    const resetForm = useCallback(async () => {
        setSelectedUser('');
        setSelectedProperty('');
        setOfferPrice('');
        setIsChecked('');
        showLoader();
        setUserList([]);
        setExistingPrice({});
    }, [showLoader]);

    const fetchUsersList = useCallback(async () => {
        resetForm();
        try {
            const response = await getUserListings();
            if (response.code === 200) {
                setUserList(response.response);
            }
            else {
                setErrorMessage("You do not have permission.");
            }
            setShowAdminModal((prevState) => !prevState);
            hideLoader();
        } catch (error) {
            hideLoader();
        }
    }, [hideLoader, resetForm]);

    const handleLogout = async () => {
        showLoader()
        try {
           await userLogout();
        } 
        catch (error) {}
        logout();
        localStorage.clear();
        sessionStorage.clear();
        hideLoader()
        navigate('/login');
    };
    
    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
    };

    const toggleAdminModal = () => {
        if(showAdminModal){
            setShowAdminModal((prevState) => !prevState);
        }
        else {
            showLoader();
            fetchUsersList();
        }
    };

    const handleUserListChange = async (event) => {
        setSelectedUser(event.target.value);
        setPropertyList([]);
        if(event.target.value !== ''){
            showLoader();
            const propertyId = event.target.value;
            const response = await getPropertyListings(propertyId);
            if (response.code === 200) {
                setPropertyList(response.response);
            }
            // hideLoader();
        }
    }

    const handlePropertyListChange = (event) => {
        setExistingPrice({});
        if(event.target.value !== ''){
            setSelectedProperty(event.target.value);
            const filter_list = propertyList.find((fl) => fl.property_id === event.target.value)
            setExistingPrice(filter_list);
        }
    }

    const handleInputChange = (e) => {
        setOfferPrice(e.target.value);
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(JSON.parse(event.target.value));
    };

    const updateOfferPrice = async () => {
        try {
            showLoader();
            const make_payload = {
                "user_id": selectedUser,
                "mlsid": selectedProperty,
                "offer_price_adjustment": offerPrice,
                "is_calculate": isChecked,
            }
            const response = await updateOfferManually(make_payload);
            if (response.code === 200) {
                setShowAdminModal((prevState) => !prevState);   
                toast.success("Offer price updated."); 
            }
            hideLoader();
        }
        catch (error) {
            toast.error("Failed to save data.");
        }
    };

    // =====================================Change password modal ==================
    // =============================================================================
    const openChangeModal = async () =>  {
        setChangeModal(true);
    };

    const handleModalClose = () => {
        setChangeModal(false); // Update parent state when modal closes
    };

    return (
        <>
            <div className="header d-flex flex-row justify-content-between p-3">
                <div className="left-side col-lg-4">
                    <img loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/logo/logo.png`} />
                    <b onClick={() => navigate('/')} className="hm_astra">Agent Mira</b>
                </div>
                <div className="center col-lg-4 d-flex justify-content-center"></div>
                <div className="right-side d-flex flex-row col-lg-4 justify-content-end">
                    {location.pathname !== '/login' && token && (
                        <>
                            {location.pathname !== '/' && (
                                <div className="button-secondary" onClick={() => navigate('/')}>
                                    <FontAwesomeIcon icon={faHome} className="clr-blue" />
                                </div>
                            )}

                            <div className="dropdown">
                                <button className="button-secondary" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" >
                                    <FontAwesomeIcon icon={faUser} />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <li>
                                        <div className="dropdown-item" onClick={openChangeModal}>
                                            <FontAwesomeIcon icon={faLock} className="me-2 clr-blue" /> Reset Password
                                        </div>
                                    </li>
                                    <li>
                                        <div className="dropdown-item" onClick={toggleModal}>
                                            <FontAwesomeIcon icon={faSignOutAlt} className="me-2 clr-blue" /> Logout
                                        </div>
                                    </li>
                                    {userDetails?.isAdmin === 1 && (
                                        <li>
                                            <div className="dropdown-item" onClick={toggleAdminModal}>
                                                Settings
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </>
                    )}
                </div>
            </div>
            
            {/* Change password modal */}
            {changeModal &&
                <>
                    <ChangePassword showChangeModal={changeModal} onClose={handleModalClose} />
                </>
            }

            {/* Logout Modal */}
            <Modal show={showModal} onClose={() => toggleModal('',6)} cswidth={cs_width}>
                <h4>Are you sure want to logout ?  </h4>
                <div className="d-flex js-center mt-3">
                    <button onClick={handleLogout}>Logout</button>
                </div>
            </Modal>

            {/* Admin manual price update modal */}
            <Modal show={showAdminModal} onClose={() => toggleAdminModal('',6)} cswidth={admin_modal_width}>
                {userList?.length > 0 && <><h4>Update offer price {existingPrice && <> for<b> {existingPrice?.property_address}</b></>}</h4></>}
                <div className="container">
                    {userList?.length === 0 && <><h4 className="text-center">{errorMessage}</h4></>}

                    {userList?.length > 0 && 
                    <>
                        <div className="d-flex gap-30">
                            {existingPrice && <>
                            <p className="fw-bold">List Price: {existingPrice?.list_price}</p>
                            <p className="fw-bold">Sugested Price: {existingPrice?.suggested_offer_price}</p>
                            </>}
                        </div>

                        <div className="d-flex gap-30">
                            <div className="w-100">
                                <label htmlFor="user_list" className="form-label">Select User<span className="clr_red">*</span></label>
                                <select className="form-select custom-select" id="user_list" value={selectedUser} onChange={handleUserListChange} >
                                    <option value="">Select user</option>
                                    {userList.map((user, index) => (
                                        <option key={index} value={user.user_id}>
                                            {user.first_name}  {user.last_name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="w-100">
                                <label htmlFor="property_list" className="form-label">Select Property<span className="clr_red">*</span></label>
                                <select disabled={selectedUser === ''} className="form-select custom-select" id="property_list" value={selectedProperty} onChange={handlePropertyListChange} >
                                    <option value="">Select an option</option>
                                    {propertyList.map((option, index) => (
                                        <option key={index} value={option.property_id}>
                                            {option.property_address}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="w-100">
                                <label htmlFor="inputField" className="form-label">Enter price<span className="clr_red">*</span></label>
                                <input disabled={(selectedUser === '' || selectedProperty === '')} type="text" className="form-control"  id="offer_price" placeholder="Enter price..." value={offerPrice}  onChange={handleInputChange} />
                            </div>                        
                        </div>

                        <div className="d-flex justify-content-between align-items-center mt-5">
                            {/* Checkbox on the left */}
                            <div className="d-flex align-items-start position-absolute gap-30">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="options" id="addition" value="true" onChange={handleCheckboxChange} />
                                    <label className="form-check-label ms-2 mt-1 cursor-pointer" htmlFor="addition">
                                        Addition
                                    </label>
                                </div>

                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="options" id="subtract" value="false" onChange={handleCheckboxChange} />
                                    <label className="form-check-label ms-2 mt-1 cursor-pointer" htmlFor="subtract">
                                        Subtraction
                                    </label>
                                </div>
                            </div>

                            {/* Button in the center */}
                            <div className={`button-secondary mw-10em mx-auto text-center ${(selectedUser === '' || selectedProperty === '' || offerPrice === '' || isChecked === '') ? 'disable' : ''}`} onClick={updateOfferPrice}>
                                Update Price
                            </div>
                        </div>
                    </>
                    }

                </div>
            </Modal>
        </>
    )
}

export default Header