import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { getOfferSuggested } from '../../slices/preferenceSlice';

const PropertyTabs = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const propertyId = useSelector((state) => state.propertySlice.propertyId);
    const offerPrice = useSelector(getOfferSuggested);

    // const getProgressWidth = () => {
    //     switch (location.pathname) {
    //         case `/${propertyId}/manor`:
    //             return '48%';
    //         case `/${propertyId}/match`:
    //             return '100%';
    //         default:
    //             return '0%';
    //     }
    // };

    return (
        <>
            <div className="ps-sticky mt-2">
                <div className="grid-item">
                    <section className="pre_chat_separator_container_2">
                        
                        <div className="pre_chat_match_container gap-10">
                            {location.pathname === `/${propertyId}/market` ?
                                <>
                                    <div className="pre_chat_match_icon p-2 bg-highlight" onClick={() => navigate(`/${propertyId}/market`)}>
                                        <div className="pre_chat_market_container">
                                            <img className="pre_chat_market_container_child" alt="" src={`${process.env.PUBLIC_URL}/images/market/group-758491.svg`} />
                                            <b className="pre_chat_market1">Home Value</b>
                                        </div>
                                    </div>
                                    
                                    <div className={`pre_chat_match_icon pe-0 ${!offerPrice?.estimatePrice ? 'disable' : ''}`} onClick={() => navigate(`/${propertyId}/match`)}>
                                        <div className="pre_chat_iconamooncompare_fill">
                                            <img className="pre_chat_material_symbolsconstruction_icon" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/market/materialsymbolsconstruction.svg`} />
                                        </div>
                                        <div className="pre_chat_manor">Lifestyle Fit</div>
                                    </div>
                                </>
                            :
                            location.pathname === `/${propertyId}/match` ?
                                <>
                                    <div className="pre_chat_match_icon p-2" onClick={() => navigate(`/${propertyId}/market`)}>
                                        <div className="pre_chat_iconamooncompare_fill">
                                            <img className="pre_chat_market_container_child" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/market/tick.svg`} />
                                        </div>
                                        <div className="pre_chat_manor">Home Value</div>
                                    </div>
                                    
                                    <div className="pre_chat_match_icon p-2 bg-highlight" onClick={() => navigate(`/${propertyId}/match`)}>
                                        <div className={location.pathname === `/${propertyId}/match` ? 'pre_chat_iconamooncompare_fill pre_tab_active' : 'pre_chat_iconamooncompare_fill'}>
                                            <img className="pre_chat_material_symbolsconstruction_icon" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/icons/match_white.svg`} />
                                        </div>
                                        <div className="pre_chat_manor">Lifestyle Fit</div>
                                    </div>
                                </>
                            : <> </>}
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default PropertyTabs;
