import axios from 'axios';
import { toast } from 'react-toastify';

const platform_url = process.env.REACT_APP_PLATFORM_URL;

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 120000, // 120 seconds timeout
});

// Request interceptor to add headers or log request details
apiClient.interceptors.request.use(
    (config) => {
        // config.headers['Authorization'] = 'Bearer token';
        const token = sessionStorage.getItem('token'); // Get token from local storage
        if (token && config.url !== '/uam/login') {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor to handle responses globally
apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error.response || error.message);
    }
);

// Generic API request function
const apiRequest = async (method, url, data = null, responseType = 'json') => {
    try {
        const response = await apiClient.request({
            method,
            url,
            data,
            responseType
        });
        
        if (responseType === 'json') {
            if (response?.data?.code === 200) {
                return response.data;
            }
            else {
                return response;
            }
        }
        else {
            return response;
        }
    } 
    catch (error) {
        if(error.status === 401){
            toast.error("Session expired, logging out.");
            handleSessionExpiration();
        }
        return error;
    }
};

const handleSessionExpiration = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/login'; // Redirect to login page
};

// ====================================Global Service==================
// User Login
export const userLogin = async (payload) => {
    return apiRequest('post', '/uam/login', payload)
};

// User Login
export const userSSOLogin = async (payload) => {
    return apiRequest('post', '/uam/login/google', payload)
};

// User logout
export const userLogout = async () => {
    return apiRequest('post', '/uam/logout', {})
};

// User Registration
export const registerNewUser = async (payload) => {
    return apiRequest('post', '/uam/signup', payload)
};

// User Registration
export const forgotPassword = async (payload) => {
    return apiRequest('post', '/uam/forgot-password', payload)
};

// Sent OTP
export const recordotp = async (payload,query) => {
    return apiRequest('post', '/uam/reset-password/'+query, payload)
};

// Resend OTP
export const resendOtp = async (payload,query) => {
    return apiRequest('post', '/uam/resend-password'+query, payload)
};

// Change Password
export const changeUserPassword = async (payload) => {
    return apiRequest('post', '/uam/change-password', payload)
};

// Verify user otp
export const verifyUser = async (payload,query) => {
    return apiRequest('post', '/uam/validate-identity/'+query, payload)
};

// ====================================Global Service==================





// ======================================Platform URL ===================
// Update user access
export const userInfo = async () => {
    return apiRequest('get', platform_url+'/user/info')
};

// Specific API functions
export const searchProperties = async (query) => {
    return apiRequest('post', platform_url+'/property/search', query);
};

// Get property details
export const getPropertyInformation = async (query) => {
    return apiRequest('get', platform_url+'/property/' + query);
};

// Get property estimates
export const getPropertyEstimate = async (query) => {
    return apiRequest('get', platform_url+'/property-external/' + query + '/estimates');
};

// Get property estimates
export const getPropertyFacts = async (query) => {
    return apiRequest('get', platform_url+'/property-external/' + query + '/fact');
};

// Get user prefrence QNA
export const getUserPrefrencesQNA = async (mlsid) => {
    return apiRequest('get', platform_url+'/agent-qna/' + mlsid + '/user_preferences');
};

// Get Market QNA
export const getQuestions = async (mlsid,page) => {
    return apiRequest('get', platform_url+'/agent-qna/' + mlsid + '/'+page);
};

// Save questions
export const saveQnaQuestion = async (query, pageName, mlsid) => {
    return apiRequest('post', platform_url+'/agent-qna/' + mlsid + '/'+pageName, query);
};

// Get All pages question lists
export const getAllQuestions = async () => {
    return apiRequest('get', platform_url+'/agent-qna/all-pages');
};

// Agent Mira Chat API 
export const getChatResponse = async (query, mlsid) => {
    return apiRequest('post', platform_url+'/agent-chat/' + mlsid, query);
};

// Get all pages info
export const getPageInformation = async (mlsid) => {
    return apiRequest('get', platform_url+'/agent-qna/' + mlsid);
};

// ================================================================
// Get marked list based on page
export const getMarkedList = async (page, mlsid) => {
    return apiRequest('get', platform_url+'/agent-qna/' + mlsid + '/marked/' + page);
};

// Property Market charts
export const propertyMarketCharts = async (payload) => {
    return apiRequest('post', platform_url+'/agent-qna/market/chart/data', payload)
};

// Property Market Range
export const propertyMarketRange = async (payload) => {
    return apiRequest('post', platform_url+'/agent-qna/market/range', payload)
};

// Get CMA
export const getCmaList = async (mlsid) => {
    return apiRequest('get', platform_url+'/property/' + mlsid + '/cma')
};

// Get offer download
export const offerDownload = async (payload) => {
    return apiRequest('get', platform_url+'/property/' + payload + '/offer_download')
};

// Get offer details
export const getSuggestedOffer = async (mlsid, pageName, payload) => {
    return apiRequest('post', platform_url+'/property/' + mlsid + "/" + pageName +"/offer", payload);
};

// Get offer details
export const sendOfferEmail = async (payload) => {
    return apiRequest('post', platform_url+'/property/offer/email', payload)
};

// Get images
export const getPropertyImages = async (payload) => {
    return apiRequest('post', platform_url+'/property-images/get/images', payload)
};

// Get user history
export const getAllUserHistory = async (mlsid, page) => {
    return apiRequest('get', platform_url+'/user/' + mlsid + '/' + page + '/history')
};

// Request user access
export const requestForAccessUpdate = async () => {
    return apiRequest('get', platform_url+'user/subscribe')
};

// Save user nick name
export const saveUserName = async (payload) => {
    return apiRequest('post', platform_url+'/user/save_nick_name', payload)
};

// Generate Offer PDF
export const generateOfferReport = async (listing_id,payload) => {
    return apiRequest('post', platform_url+'/property/'+listing_id+'/generate-pdf', payload)
};

// Get status of Offer PDF
export const getStatusOfOfferDownload = async (mlsid,pid) => {
    return apiRequest('get', platform_url+'/property/status/'+mlsid +"/" + pid)
};

// Download Offer PDF
export const downloadOfferReport = async (mlsid,pid) => {
    return apiRequest('get', platform_url+'/property/download/'+mlsid +"/" + pid, null, 'blob')
};

// Generate CMA PDF
export const generateCmaReport = async (listing_id,payload) => {
    return apiRequest('post', platform_url+'/property/'+listing_id+'/generate-cma', payload)
};

// Generate CMA PDF
export const getAccountHistory = async (listing_id) => {
    return apiRequest('get', platform_url+'/property/'+listing_id+'/account_history')
};

// Get property details
export const getPropertyInfo = async (listing_id) => {
    return apiRequest('get', platform_url+'/property/'+listing_id+'/user_properties')
};

// Save Page Information
export const savePageInfo = async (pagename) => {
    return apiRequest('get', platform_url+'/property/page/'+pagename)
};

// Get user lists
export const getUserListings = async () => {
    return apiRequest('get', platform_url+'/property/fetch/userlist')
};

// Get propery lists
export const getPropertyListings = async (mlsid) => {
    return apiRequest('get', platform_url+'/property/propertylist/'+mlsid)
};

// Get propery lists
export const updateOfferManually = async (payload) => {
    return apiRequest('post', platform_url+'/property/update/offer_price', payload)
};

// Get tagged image
export const getTagImages = async (mlsid) => {
    return apiRequest('get', platform_url+'/property-images/'+mlsid+'/view-image-tagging')
};

// Get tagged images list
export const taggedImageList = async (mlsid, category) => {
    return apiRequest('get', platform_url+'/property-images/'+mlsid+'/'+category+'/features-image-id')
};

// Get tagged images list
export const getSingleImage = async (mlsid, image_id) => {
    return apiRequest('get', platform_url+'/property-images/'+mlsid+'/'+image_id+'/features-image-tagging')
};

// Get view type image list
export const getViewTypeList = async (mlsid) => {
    return apiRequest('get', platform_url+'/property-images/'+mlsid+'/features/view')
};

// Get tagged images list
export const getViewSingleImage = async (mlsid, image_id) => {
    return apiRequest('get', platform_url+'/property-images/'+mlsid+'/'+image_id+'/features/view-image-tagging')
};

// Get standout images list
export const getStandoutImages = async (mlsid, image_id) => {
    return apiRequest('get', platform_url+'/property-images/'+mlsid+'/'+image_id+'/features/standout-image-tagging')
};

// Get Inspection images list
export const getInspectionImage = async (mlsid, image_id) => {
    return apiRequest('get', platform_url+'/property-images/'+mlsid+'/'+image_id+'/features/inspection')
};
