// Scroll the page to bottom
export const scrollToBottom = (bottomRef) => {
    setTimeout(() => {
        if (bottomRef?.current) {
            return  bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    }, 500);
};

export const checkIsLast = (array) => {
    if(array.length === 0){
        return false
    }
    else {
        return array.some(item => item.isLast === true && !item.reponse);
    }
};

export const checkStandoutQuestion = (array) => {
    if (array.length === 0) {
        return null;
    }

    // Find the first item that meets the criteria
    const standoutFeature = array.find(
        item => item.question_type === "standout-features" && item.response_options.length > 0
    );
    return standoutFeature || null;
};

export const checkInspectionQuestion = (array) => {
    if (array.length === 0) {
        return null;
    }

    // Find the first item that meets the criteria
    const inspectionFeature = array.find(
        item => item.question_type === "inspection-features" && item.response_options.length > 0
    );
    return inspectionFeature || null;
};

export const checkViewFeatureQuestion = (array) => {
    if (array.length === 0) {
        return null;
    }
    
    // Find the first item that meets the criteria
    const viewFeatures = array.find(
        item => item.question_type === "view-features" && item.response_options.length > 0
    );
    return viewFeatures || null;
};

export const checkIsValid = (array) => {
    if (array?.length === 0) {
        return false;
    } else {
        return array.some(item => !item?.chat && !item.hasOwnProperty('response') && item?.response_options?.length > 0);
    }
};

// Get the last question details is true or not
export const getLastQuestion = (array) => {
    return array.find(item => item.isLast === true && (item.hasOwnProperty('response') || item.question_type === 'video' || item.question_type === 'chart-type'));
};

export const scrollToTop = () => {
    return  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const formatCommas = (number) => {
    if(typeof number === 'number' && !isNaN(number)){
        return new Intl.NumberFormat('en-US').format(number);
    }
    else {
        return 0
    }
};

export const getMaxValue = (data) => {
    const maxDataValue = Math.max(...data);
    return maxDataValue > 100 ? 100 : maxDataValue;
};

export const calculateInterval = (maxValue) => {
    return Math.ceil(maxValue / 10);
};

export const shouldDispatchQuestion = (response, questionList) => {
    // Condition 1: If questionList is empty
    if (questionList.length === 0) {
        return true;
    }
    
    // Condition 2: If question_id already exist in the history array
    if (!questionList.some(q => q.question_id === response.question_id)) {
        return true;
    }

    return false;
};

export const areObjectsEqual = (obj1, obj2) => {
    if (!obj1 || !obj2) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    return true;
};

export  const validatePropertyId = (id) => {
    if (!id || id.trim() === '') {
        return false
    }
    else {
        if (id.trim().length > 3) {
            return true;
        }
        else {
            return false
        }
    }
}

export const sessionExists = () => {
    const session = sessionStorage.getItem('token');
    return session !== null;
};

// Combines user history and marked API data into one single array
export const DataBinder = (userHistory, markedResponse) => {
    let outputdata = [];
    if(userHistory.length > 0){
        userHistory.forEach(ques => {
            if (ques?.event?.toLowerCase() === "q") {
                const matchedQuestion = markedResponse?.find(item2 => item2.question_id === ques.question);
                if (matchedQuestion) {
                    const exists = outputdata.some(item => item.question_id === matchedQuestion.question_id);
                    if (!exists) {
                        outputdata.push(matchedQuestion);
                    }
                }
            }
            else if (ques?.event?.toLowerCase() === "p") {
                const modifiedItem = {
                    ...ques,
                    isOfferPrice: true,
                    isSaved: true
                };
                outputdata.push(modifiedItem);
            }
            else if (ques?.event?.toLowerCase() === "c") {
                const modifiedItem = {
                    ...ques,
                    chat: true,
                    sender: ques.actor.toLowerCase() === "u" ? 'user' : 'mira',
                    message: ques.text
                };
                outputdata.push(modifiedItem);
            }
        });
        return outputdata;
    }
    else {
        return markedResponse;
    }
}

// Get the check based on the question type (single-select, single-select-emoji & multiple select)
export const getUpdatedResponseBasedOnQtype = (questionList, question, option, checkedStatus) => {
    if (question.question_type === 'single-select' || question.question_type === 'single-select-emoji' || question.question_type === "quality-score") {
        return questionList.map(ques =>
            ques.question_id === question.question_id
                ? {
                    ...ques,
                    response_options: ques.response_options.map(op =>
                        op.option_id === option.option_id
                            ? { ...op, isChecked: true }
                            : { ...op, isChecked: false }
                    )
                }
                : ques
        );
    } 
    else {
        return questionList.map(ques =>
            ques.question_id === question.question_id
                ? {
                    ...ques,
                    response_options: ques.response_options.map(op =>
                        option.option_id === op.option_id
                            ? { ...op, isChecked: checkedStatus }
                            : checkedStatus && option.exclusive === 1
                                ? { ...op, isChecked: false }
                                : checkedStatus && option.exclusive === 0 && op.exclusive === 1
                                    ? { ...op, isChecked: false }
                                    : op
                    )
                }
                : ques
        );
    }
};

// Function to return the confidence score based on rating
export const renderStars = (rating) => {
    const filledStars = Array(rating).fill(
      <img className="mrk_star_icon" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/market/star.svg`} />
    );
  
    const emptyStars = Array(5 - rating).fill(
      <img className="mrk_star_icon4" alt="" src={`${process.env.PUBLIC_URL}/images/market/star-3.svg`} />
    );
  
    return [...filledStars, ...emptyStars];
};

export const getOfferDataFromHistory = (mainqList) => {
    return mainqList.find(ques => ques.event === "P");
};