import React, { useEffect, useState, useContext, useCallback } from "react";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { login } from '../../slices/loginSlice.js';
import './login.css';
import imageSrc from '../../assets/images/google.png';
import { sessionExists } from '../utils/Common';
import { userLogin, getPropertyInfo, propertyMarketRange, registerNewUser, userSSOLogin, userInfo } from "../services/apiService.js";
import { useAuth } from '../utils/AuthContext.js';
import { LoaderContext } from '../services/LoaderContext';

import { setPropertyAddress, setPropertyId, setPropertyDetails, setPropertyImages, clearPropertyDetails } from '../../slices/propertySlice';
import { clearSuggestedOffer, clearMarket, clearManor, setCmaList, clearPreferenceQuestionList } from '../../slices/preferenceSlice';
import { clearMarketQuestionList, setRange, clearRangeList, } from '../../slices/marketSlice';
import { clearManorQuestionList } from '../../slices/manorSlice';
import { clearMatchQuestionList } from '../../slices/matchSlice';
import { useGoogleLogin } from '@react-oauth/google';
import ForgotPassword from "../admin-management/forgot-password.jsx";
import VerificationModal from "../admin-management/verification-modal.jsx";

const Login = () => {
    const { login: authLogin } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [formType, setFormType] = useState(false);
    const [errors, setErrors] = useState({});

    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [loginAPIResponse, setLoginAPIResponse] = useState(null);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [contact, setContact] = useState('');
    const [homeJourney, setHomeJourney] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [payload, setPayload] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [forgotModal, setForgotModal] = useState(false);
    const [verifyModal, setVerifyModal] = useState(false);

    // Phone Validation
    const validatePhone = useCallback((phone) => {
        const re = /^\d{10}$/; // Ensure the phone number is exactly 10 digits
        return re.test(phone);
    }, []);

    useEffect(() => {
        if (sessionExists()) {
            navigate(`/`);
        }
    }, [navigate]);

    const validateEmail = useCallback((email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }, []);

    const getRange = useCallback(async (list) => {
        dispatch(clearRangeList());
        try {
            const response = await propertyMarketRange({ "listing_id": list.propertyId });
            if (response.code === 200) {
                dispatch(setRange(response.response));
            }
        } catch (error) {
            // handle error if necessary
        }
    }, [dispatch]);

    const getPropertyInformation = useCallback(async (list) => {
        const response = await getPropertyInfo(list.propertyId);
        if (response.code === 200) {
            if (Array.isArray(response?.response) && response?.response?.length === 0) {
                toast.success("Login successful!");
                hideLoader();
                navigate(`/`);
            }
            else {
                const propertyInfo = response.response;
                // Clear previous property-related info in Redux store
                dispatch(clearPropertyDetails());
                dispatch(clearMarketQuestionList());
                dispatch(clearPreferenceQuestionList());
                dispatch(clearMatchQuestionList());
                dispatch(clearManorQuestionList());
                dispatch(clearSuggestedOffer());
                dispatch(clearManor());
                dispatch(clearMarket());
    
                // Set new property information
                dispatch(setPropertyAddress(propertyInfo.property_address));
                dispatch(setPropertyId(list.propertyId));
                dispatch(setCmaList(propertyInfo.cma_list));
                if (propertyInfo?.images && propertyInfo.images.length > 0) {
                    dispatch(setPropertyImages(propertyInfo.images[0]));
                }
    
                if (propertyInfo?.cma_list?.subjectProperty) {
                    dispatch(
                        setPropertyDetails({
                            propertyBeds: propertyInfo.cma_list.subjectProperty.BedroomsTotal,
                            propertyBaths: propertyInfo.cma_list.subjectProperty.BathroomsTotalDecimal,
                            propertySqft: propertyInfo.cma_list.subjectProperty.LivingArea,
                            propertyListPrice: propertyInfo.cma_list.subjectProperty.ListPrice,
                            propertyUnparsedAddress: propertyInfo.cma_list.subjectProperty.UnparsedAddress,
                        })
                    );
                }
                
                toast.success("Login successful!");
                hideLoader();
                navigate(`/${atob(list?.isAccessed)}`, { replace: true });
            }
        }
        else {
            hideLoader();
        }
    }, [dispatch, hideLoader, navigate]);

    const routeToHomePage = useCallback(async (response) => {
        try {
            const user_response = { ...response.response };
            dispatch(login(user_response)); // Initial dispatch with the login response

            authLogin(user_response.token);
            sessionStorage.setItem('token', user_response.token);

            const infoResponse = await userInfo();

            if (infoResponse.code === 200) {
                // Merge additional details from infoResponse into user_response
                Object.assign(user_response, {
                    full_access: infoResponse?.response?.full_access,
                    isAccessed: infoResponse?.response?.isAccessed,
                    is_recurring: infoResponse?.response?.is_recurring,
                    lite_access: infoResponse?.response?.lite_access,
                    nick_name: infoResponse?.response?.nick_name,
                    propertyId: infoResponse?.response?.propertyId,
                });

                // Dispatch updated user_response
                dispatch(login(user_response));

                // Navigate or fetch additional information based on conditions
                if (infoResponse?.response?.isAccessed === '') {
                    toast.success("Login successful!");
                    navigate(`/`);
                    hideLoader();
                }
                else {
                    getRange(infoResponse.response);
                    getPropertyInformation(infoResponse.response);
                }
            } 
            else {
                hideLoader();
                toast.error(infoResponse?.data?.message || "Failed to fetch user information");
            }
        } catch (error) {
            toast.error("An unexpected error occurred. Please try again.");
        } 
    }, [authLogin, dispatch, hideLoader, navigate, getPropertyInformation, getRange]);


    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        const errors = {};
        
        if (!validateEmail(loginEmail)) errors.loginEmail = "Please enter a valid email address";
        if (!loginPassword) errors.loginPassword = "Password is required";
        
        setErrors(errors);
        
        if (Object.keys(errors).length > 0) {
            return;
        }
        
        showLoader();
        try {
            const payload = { "username": loginEmail, "password": loginPassword };
            const response = await userLogin(payload);
            if (response.code === 200) {
                if(response?.response?.is_auth === 0){
                    hideLoader();
                    setVerifyModal(true);
                    setLoginAPIResponse(response);
                }
                else {
                    routeToHomePage(response);
                }
            }
            else {
                toast.error(response.data.message);
                hideLoader();
            }
        }
        catch (error) {
            hideLoader();
            toast.error("Login failed. Please try again.");
        }
    };

    const handleGoogleLoginSuccess = async (tokenResponse) => {
        try {
            const { access_token } = tokenResponse;
            // Send the token to the backend for verification and user handling
            const response = await userSSOLogin({ "google_token": access_token });
            if (response.code === 200) {
                routeToHomePage(response);
            }
            else {
                toast.error("Authentication failed. Please try again.");
            }
        }
        catch (error) {
            toast.error("Authentication failed. Please try again.");
        }
    };

    // Configure Google Login
    const loginWithGoogle = useGoogleLogin({
        onSuccess: handleGoogleLoginSuccess,
        onError: () => toast.error("Google Login Failed"),
    });

    // =====================================Signup================================
    // ===========================================================================
    const validateForm = useCallback(() => {
        const newErrors = {
            firstName: firstName.trim() === '' ? "First name is required." : '',
            lastName: lastName.trim() === '' ? "Last name is required." : '',
            email: email.trim() === '' ? "Email is required." : !validateEmail(email) ? "Enter a valid email address." : '',
            password: password.trim() === '' ? "Password is required." : password.length < 5 ? "Password must be at least 5 characters." : '',
            contact: contact.trim() === '' ? "Contact number is required." : !/^\d{10}$/.test(contact) ? "Contact number must be exactly 10 digits." : '',
            homeJourney: homeJourney === '' || homeJourney === 'Select an option' ? "Please select a valid stage in your home journey." : '',
        };

        const firstError = Object.values(newErrors).find((error) => error !== '');
        if (firstError && isSubmitted) {
            toast.error(firstError); // Show the first error message
        }

        // Return the validation status (true if valid, false if invalid)
        return Object.values(newErrors).every((error) => error === '');
    }, [firstName, lastName, email, password, contact, homeJourney, isSubmitted, validateEmail]);

    const handleSignupSubmit = async (e) => {
        e.preventDefault();
        const errors = {};

        if (!firstName.trim()) errors.firstName = "First name is required";
        if (!lastName.trim()) errors.lastName = "Last name is required";
        if (!validatePhone(contact)) errors.contact = "Phone number must be 10 digits";
        if (!homeJourney) errors.homeJourney = "Please select a home journey stage";
        if (!validateEmail(email)) errors.email = "Please enter a valid email address";
        if (!password) errors.password = "Password is required";

        setErrors(errors);

        if (Object.keys(errors).length > 0) {
            return;
        }

        // Mark form as submitted
        setIsSubmitted(true);

        // Validate the form
        const isValid = validateForm();
        if (!isValid) {
            // Prevent submission if form is invalid
            return;
        }

        try {
            showLoader();
            const payload = {
                first_name: firstName,
                last_name: lastName,
                email_address: email,
                state: "NW",
                password: password,
                home_journey: homeJourney,
                phone_number: contact
            };

            const response = await registerNewUser(payload);
            if (response.code === 200) {
                setPayload(payload);
                setFirstName('');
                setLastName('');
                setEmail('');
                setPassword('');
                setContact('');
                setHomeJourney('');
                setIsSubmitted(false); // Reset submission state
                toast.success(`${firstName} user registered successfully!`);
                setShowPopup(true);

                setTimeout(() => {
                    closeSuccessModal();
                }, 2000);
            }
            else {
                toast.error(response.message);
            }
            hideLoader();
        } catch (error) {
            hideLoader();
            toast.error("Registration failed. Please try again.");
        } finally {
            hideLoader();
        }
    };

    const closeSuccessModal = () => {
        setShowPopup(false);
        setPayload({});
        setFormType(false)
    }

    // =====================================Forgot password modal ==================
    // =============================================================================
    const openForgotModal = async () => {
        setForgotModal(true);
    };

    const handleModalClose = () => {
        setForgotModal(false); // Update parent state when modal closes
        setVerifyModal(false);
    };

    const returnUser = () => {
        routeToHomePage(loginAPIResponse);
    }

    return (
        <>
            <div className="login-container">
                <div className="image-section"></div>
                <div className="card-section">
                    <div className={`chart_card custom-padding ${formType ? 'signup-h ' : ''}`}>
                        <div className="card-body">
                            <div className="d-flex gap-30 align-items-center mb-3">
                                <div className="logo">
                                    <img loading="lazy" width="50" alt="" src={`${process.env.PUBLIC_URL}/images/logo/logo.png`} />
                                </div>
                                <div className="sub-text">
                                    <h3 className="title-text text-center">Offer Savant</h3>
                                    <p className="card-title txt text-center mb-3">Let's craft a winning offer together</p>
                                </div>
                            </div>

                            {formType && (
                                <>
                                    <form onSubmit={handleSignupSubmit} autoComplete="off">
                                        <div className="mb-3">
                                            <label htmlFor="firstName" className="form-label form-text">
                                                First Name <span>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control custom-ip ${errors.firstName ? "is-invalid" : ""}`}
                                                id="firstName"
                                                placeholder="Enter your first name"
                                                value={firstName}
                                                onChange={(e) => {
                                                    setFirstName(e.target.value);
                                                    setErrors({ ...errors, firstName: "" });
                                                }}
                                            />
                                            {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="lastName" className="form-label form-text">
                                                Last Name <span>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control custom-ip ${errors.lastName ? "is-invalid" : ""}`}
                                                id="lastName"
                                                placeholder="Enter your last name"
                                                value={lastName}
                                                onChange={(e) => {
                                                    setLastName(e.target.value);
                                                    setErrors({ ...errors, lastName: "" });
                                                }}
                                            />
                                            {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="phoneNo" className="form-label form-text">
                                                Phone No <span>*</span>
                                            </label>
                                            <input
                                                type="number"
                                                className={`form-control custom-ip ${errors.contact ? "is-invalid" : ""}`}
                                                id="phoneNo"
                                                placeholder="Enter your phone number"
                                                value={contact}
                                                onChange={(e) => {
                                                    setContact(e.target.value);
                                                    setErrors({ ...errors, contact: "" });
                                                }}
                                            />
                                            {errors.contact && <div className="invalid-feedback">{errors.contact}</div>}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="home_jurney" className="form-label">
                                                What stage are you at in your home journey? <span>*</span>
                                            </label>
                                            <select
                                                className={`form-select ${errors.homeJourney ? "is-invalid" : ""}`}
                                                id="home_jurney"
                                                value={homeJourney}
                                                onChange={(e) => {
                                                    setHomeJourney(e.target.value);
                                                    setErrors({ ...errors, homeJourney: "" });
                                                }}
                                            >
                                                <option>Select an option</option>
                                                <option value="Just getting started">Just getting started</option>
                                                <option value="Casually looking">Casually looking</option>
                                                <option value="Actively looking">Actively looking</option>
                                                <option value="Ready to close">Ready to close</option>
                                            </select>
                                            {errors.homeJourney && <div className="invalid-feedback">{errors.homeJourney}</div>}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label form-text">
                                                Email address <span>*</span>
                                            </label>
                                            <input
                                                type="email"
                                                className={`form-control custom-ip ${errors.email ? "is-invalid" : ""}`}
                                                id="email"
                                                placeholder="Enter your email"
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                    setErrors({ ...errors, email: "" });
                                                }}
                                            />
                                            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="password" className="form-label form-text">
                                                Password <span>*</span>
                                            </label>
                                            <input
                                                type="password"
                                                className={`form-control custom-ip ${errors.password ? "is-invalid" : ""}`}
                                                id="password"
                                                placeholder="Enter your password"
                                                onChange={(e) => {
                                                    setPassword(e.target.value);
                                                    setErrors({ ...errors, password: "" });
                                                }}
                                            />
                                            {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                        </div>

                                        <button type="submit" className="btn btn-primary custom-btn w-100 mt-4">
                                            Register
                                        </button>
                                    </form>

                                    <button type="button" className="btn btn-primary custom-btn-g w-100 mt-3" onClick={loginWithGoogle}>
                                        <img src={imageSrc} alt="Sign In" className="text-start sign-in" /> Sign up with Google
                                    </button>

                                    <button type="button" className="btn btn-primary custom-btn-g w-100 mt-3" onClick={() => setFormType(false)}>
                                        Back to Login
                                    </button>
                                </>
                            )}

                            {!formType && (
                                <>
                                    <form onSubmit={handleLoginSubmit} autoComplete="off">
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label form-text">
                                                Email address <span>*</span>
                                            </label>
                                            <input
                                                type="email"
                                                className={`form-control custom-br ${errors.loginEmail ? "is-invalid" : ""}`}
                                                id="email"
                                                placeholder="Enter your email"
                                                value={loginEmail}
                                                onChange={(e) => {
                                                    setLoginEmail(e.target.value);
                                                    setErrors({ ...errors, loginEmail: "" });
                                                }}
                                            />
                                            {errors.loginEmail && <div className="invalid-feedback">{errors.loginEmail}</div>}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="password" className="form-label form-text">
                                                Password <span>*</span>
                                            </label>
                                            <input
                                                type="password"
                                                className={`form-control custom-br ${errors.loginPassword ? "is-invalid" : ""}`}
                                                id="password"
                                                placeholder="Enter your password"
                                                onChange={(e) => {
                                                    setLoginPassword(e.target.value);
                                                    setErrors({ ...errors, loginPassword: "" });
                                                }}
                                            />
                                            {errors.loginPassword && <div className="invalid-feedback">{errors.loginPassword}</div>}
                                        </div>

                                        <button type="submit" className="btn btn-primary custom-btn w-100 mt-4">Submit</button>
                                        <div className="d-flex js-center mt-2">
                                            <span className="forgot-text" onClick={openForgotModal}>Forgot Password?</span>
                                        </div>
                                    </form>

                                    <button type="button" className="btn btn-primary custom-btn-g w-100 mt-3" onClick={loginWithGoogle}>
                                        <img src={imageSrc} alt="Sign In" className="text-start sign-in" /> Sign in with Google
                                    </button>

                                    <button type="button" className="btn btn-primary custom-btn-g w-100 mt-3" onClick={() => {setFormType(true);setErrors({});}}>
                                        <span>Don't have an account? </span> Sign up
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {showPopup ?
                <div className="offer_done_popup">
                    <div className="popup_container flex-column justify-content-center align-center w-40">
                        <img alt="" src={`${process.env.PUBLIC_URL}/images/market/group-1171275857.svg`} style={{ "width": "50%" }} />
                        <div className="heres-your-offer">
                            Congratulations!
                        </div>
                        <p className="mt-3"><span className="fw-bold">{payload?.email_address}</span></p>
                        <p className="mt-2">
                            <span className="fw-bold">{payload?.first_name} {payload?.last_name}</span> has been registered successfully!
                        </p>
                        <div className="d-flex w-100 justify-content-evenly">
                            <button className="ssx_learn-more-wrapper justify-content-center w-min" onClick={() => closeSuccessModal()}><div className="ssx_learn-more1 text-center">Back</div></button>
                        </div>
                    </div>
                </div>
            : <></>}

            {forgotModal &&
                <>
                    <ForgotPassword showForgotModal={forgotModal} onClose={handleModalClose} />
                </>
            }

            {verifyModal &&
                <>
                    <VerificationModal showVerifyModal={verifyModal} loginEmail={loginEmail} onClose={handleModalClose} onSuccess={returnUser} />
                </>
            }
        </>
    );
};

export default Login;
