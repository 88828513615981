import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import './App.css';
import './assets/css/custom.css';
import './assets/css/header.css';
import './assets/css/footer.css';
import './assets/css/small-device.css';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';
import Home from './components/home/home';
import Offer from './components/offer/offer';
import Preferences from './components/preferences/preferences';
import MarketTab from './components/property/market/market';
import MatchTab from './components/property/match/match';
import Login from './components/login/login';
import AgentMira from './components/agent-mira/mira';
import Register from './components/login/register';

import { AuthProvider } from './components/utils/AuthContext';
import AuthGuard from './components/utils/AuthGuard';
import { GoogleOAuthProvider } from "@react-oauth/google";

const clientId = process.env.REACT_APP_CLIENT_ID;

function App() {

    return (
        <BrowserRouter>
            <AuthProvider>
                <ToastContainer position="bottom-left" autoClose={3000} closeOnClick pauseOnHover={true} theme="colored" />

                <Routes>
                    <Route path="/login" element={
                        <GoogleOAuthProvider clientId={clientId}>
                            <Login />
                        </GoogleOAuthProvider>
                    } />

                    <Route path="/" element={
                        <AuthGuard>
                            <Home />
                        </AuthGuard>
                    } />

                    <Route path="/:propertyid" element={
                        <AuthGuard>
                            <Home />
                        </AuthGuard>
                    } />

                    <Route path="/:propertyid/preferences" element={
                        <AuthGuard>
                            <Preferences />
                        </AuthGuard>
                    } />

                    <Route path="/:propertyid/offer" element={
                        <AuthGuard>
                            <Offer />
                        </AuthGuard>
                    } />

                    {/* Market, Match screens Start */}
                    <Route path="/:propertyid/market" element={
                        <AuthGuard>
                            <MarketTab />
                        </AuthGuard>
                    } />

                    <Route path="/:propertyid/match" element={
                        <AuthGuard>
                            <MatchTab />
                        </AuthGuard>
                    } />
                    {/* Market screens End */}

                    <Route path="/agent-mira" element={
                        <AuthGuard>
                            <AgentMira />
                        </AuthGuard>
                    } />

                    <Route path="/sign-up" element={
                        <AuthGuard>
                            <Register />
                        </AuthGuard>
                    } />

                    <Route path="/" element={
                        <AuthGuard>
                            <Home />
                        </AuthGuard>
                    } />

                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;
