import React, { useState, useEffect, useContext } from 'react';
import { LoaderContext } from '../services/LoaderContext';
import Modal from '../utils/Modal';
import { toast } from 'react-toastify';
import { changeUserPassword } from "../services/apiService";
import { useAuth } from '../utils/AuthContext';
import { useNavigate } from "react-router-dom";

const cs_width = "35%";

const ChangePassword = ({ showChangeModal, onClose }) => {

    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [showModal, setShowModal] = useState(showChangeModal);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const { logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        setShowModal(showChangeModal);
    }, [showChangeModal]);

    const handleModalClose = () => {
        // Reset the form
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setErrors({});
        setShowModal(false);
        onClose(); // Notify the parent about modal closure
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};
        // Validation
        if (!oldPassword.trim()) {
            newErrors.oldPassword = "Old password is required!"
        }
        if (!newPassword.trim()) {
            newErrors.newPassword = "New password is required!";
        }
        if (!confirmPassword.trim()) {
            newErrors.confirmPassword = "Confirm password is required!";
        }
        if (newPassword !== confirmPassword) {
            newErrors.confirmPassword = "New password and confirm password does not match!";
        }
        if (newPassword.trim() === oldPassword.trim()) {
            newErrors.confirmPassword = "New password must be different from the current password.";
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            return;
        }

        try {
            showLoader();
            const response = await changeUserPassword({ "current_password": btoa(oldPassword), "new_password": btoa(newPassword), "confirm_password": btoa(confirmPassword) });
            if (response.code === 200) {
                logout();
                toast.success("Your password has been changed. Please log in again.");
                localStorage.clear();
                sessionStorage.clear();
                hideLoader()
                navigate('/login');
            }
            else {
                hideLoader();
                toast.error("Password must be 7-14 characters long and include at least one uppercase letter, one number, and one special character.");
            }
        }
        catch (error) {
            hideLoader();
            toast.error("Unable to reset the password. Please try again later.");
        }
    };

    return (
        <Modal show={showModal} onClose={handleModalClose} cswidth={cs_width}>
            <div className="mb-2">
                <h4>Change Password</h4>
                <div className="mt-3">
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="mb-3">
                            <label htmlFor="old_password" className="form-label form-text">
                                Enter old password <span>*</span>
                            </label>
                            <input
                                type="password"
                                className={`form-control custom-br ${errors.oldPassword ? "is-invalid" : ""}`}
                                id="old_password"
                                placeholder="Enter old password.."
                                onChange={(e) => {
                                    setOldPassword(e.target.value);
                                    setErrors({ ...errors, oldPassword: "" }); // Clear error on change
                                }}
                            />
                            {errors.oldPassword && <div className="invalid-feedback">{errors.oldPassword}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="new_password" className="form-label form-text">
                                Enter new password <span>*</span>
                            </label>
                            <input
                                type="password"
                                className={`form-control custom-br ${errors.newPassword ? "is-invalid" : ""}`}
                                id="new_password"
                                placeholder="Enter new password.."
                                onChange={(e) => {
                                    setNewPassword(e.target.value);
                                    setErrors({ ...errors, newPassword: "" }); // Clear error on change
                                }}
                            />
                            {errors.newPassword && <div className="invalid-feedback">{errors.newPassword}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="confirm_password" className="form-label form-text">
                                Confirm new password <span>*</span>
                            </label>
                            <input
                                type="password"
                                className={`form-control custom-br ${errors.confirmPassword ? "is-invalid" : ""}`}
                                id="confirm_password"
                                placeholder="Confirm new password.."
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                    setErrors({ ...errors, confirmPassword: "" }); // Clear error on change
                                }}
                            />
                            {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
                        </div>

                        <div className="d-flex justify-content-center">
                            <button type="submit" data-test-id="reset-password-submit" id="reset-pass" className="button-primary mw-10em mt-2">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
};

export default ChangePassword;
