import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { scrollToBottom } from '../utils/Common';
import { useSelector, useDispatch } from 'react-redux';
import { getPropertyEstimate, getPropertyFacts, getCmaList } from '../services/apiService';
import homeChatGif from '../../assets/gifs/home_chat.gif';
import bath from '../../assets/svg/bath.svg';
import doublebed from '../../assets/svg/double-bed.svg';
import hotelbuilding from '../../assets/svg/hotelbuilding.svg';
import ChatLoader from "../services/chat-loader";
import { getImagesList } from '../../slices/propertySlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { setCmaList } from '../../slices/preferenceSlice';
import NoComparable from "../services/no-comparable";
import { loginData } from '../../slices/loginSlice';



const HomeChat = ({ propertyid }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const bottomRef = useRef(null);
    const hasFetched = useRef(false);
    const intervalRef = useRef(null); // Ref to store interval ID

    const propertyAddress = useSelector((state) => state.propertySlice.propertyAddress);
    const propertyDetails = useSelector((state) => state.propertySlice.propertyDetails);
    const imageList = useSelector(getImagesList);
    const userDetails = useSelector(loginData);

    const [currentBlock, setCurrentBlock] = useState(0);
    const [, showLoader] = useState(false);
    const [prDetails, setprDetails] = useState(propertyDetails);
    const [estimate, setPropertyEstimates] = useState({});
    const [propertyFact, setPropertyFact] = useState(null);
    const [apiError, setError] = useState(true);
    const [currentCard, setCurrentCard] = useState(0);
    const [imageSrc, setImageSrc] = useState(imageList);
    const [cmaResponse, setCmaResponse] = useState({});
    
    const confirmGetStarted = () => {
        if(userDetails?.full_access === 1){
            navigate(`/${propertyid}/preferences`);
        }
        else {
            navigate(`/${propertyid}/market`);
        }
    }

    const callInterval = useCallback(() => {
        intervalRef.current = setInterval(() => {
            setCurrentCard(prevCard => prevCard + 1);
        }, 5000);
    }, []);

    const getCMAData = useCallback(async () => {
        try {
            const response = await getCmaList(propertyid);
            if (response.code === 200) {
                dispatch(setCmaList(response.response));
                const updatedUserInformation = {
                    ...response.response,
                    status: true
                };
                setCmaResponse(updatedUserInformation);
            } else {
                setCmaResponse({ "status": false });
            }
        } catch (error) {
            setCmaResponse({ "status": false });
        }
    }, [propertyid, dispatch]);

    const fetchEstimate = useCallback(async () => {
        showLoader(true);
        try {
            const response = await getPropertyEstimate(propertyid);
            if (response.code === 200) {
                if (Array.isArray(response.response) && response.response.length === 0) {
                    setError(true);
                } else {
                    setTimeout(() => {
                        setPropertyEstimates(response.response);
                    }, 6000);
                    setError(false);
                }
            } else {
                setError(true);
            }
            showLoader(false);
            if (currentBlock < 4) {
                callInterval();
            } else {
                setError(true);
            }
        } catch (error) {
            setError(true);
            showLoader(false);
            if (currentBlock < 4) {
                callInterval();
            } else {
                setError(true);
            }
        }
    }, [propertyid, currentBlock, showLoader, callInterval]);
    
    const fetchFacts = useCallback(async () => {
        try {
            const response = await getPropertyFacts(propertyid);
            if (response.code === 200) {
                sessionStorage.setItem(propertyid, response.response.fact);
                setPropertyFact(response.response.fact);
            }
            fetchEstimate();
        } catch (error) {
            fetchEstimate();
            // toast.warning("Failed to fetch data.");
        }
    }, [propertyid, fetchEstimate]);
    
    useEffect(() => {
        if (!hasFetched.current) {
            getCMAData();
            const getFact = sessionStorage.getItem(propertyid)
            if(getFact !== undefined && getFact !== null){
                setPropertyFact(getFact);
                fetchEstimate();
            }
            else {
                fetchFacts();
            }
            hasFetched.current = true;
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [propertyid, dispatch, getCMAData, fetchEstimate, fetchFacts]);

    useEffect(() => {
        if(currentBlock < 2 && (currentCard >= 1 && ((estimate?.range?.minimum || estimate?.estimate) || (currentCard >= 2)))){
            setTimeout(() => {
                setCurrentBlock(prevCard => prevCard + 1);
            }, 6000);
            scrollToBottom(bottomRef);
        }
    }, [propertyFact, estimate, currentCard, currentBlock]);

    useEffect(() => {
        if(imageList){
            if(imageList){
                setImageSrc(imageList);
            }
        }

        if(propertyDetails){
            setprDetails(propertyDetails);
        }
    }, [imageList, propertyDetails]);

    const navigateToZillow = () => { 
        window.open(process.env.REACT_APP_ZILLOW_URL, "_blank");
    }
    

    return (
        <div className="d-flex w-100 justify-content-center">
            <div className="chat content w-60 d-flex flex-column mb-5 mt-3">
                <div className="subHeading2 ms-4 mt-5">
                    Let’s Chat!
                </div>
                {/* Message 1 */}
                <div className="message-holder mira w-80">
                    <div className="message-bubble">
                        <div className="label d-flex font-weight-bold">
                            Agent Mira
                        </div>
                        <div className="message-text">
                            <span className="fw-bold">{propertyAddress}</span> 
                            <div className="message-fact w-100 d-flex">
                                <div className="content-text w-100 mt-2">
                                    <div className="property-list-horizontal flex-row">
                                        <div className="property-image">
                                            {imageSrc ? (
                                                <img src={`data:image/jpeg;base64,${imageSrc}`} alt="" className="br-15 img-width" />
                                            ) : (
                                                <>
                                                    <div className="mrk_frame_icon bg-gray" />
                                                </>
                                            )}
                                        </div>
                                        <div className="property-details ms-3">
                                            <div className="property-price p-2 flex-content-start">
                                                List Price
                                                <span className="label p-2">
                                                    {prDetails?.propertyListPrice}
                                                </span>
                                            </div>
                                            <div className="d-flex flex-row w-100 align-items-center gap-10 box-wrap">
                                                <div className="d-flex flex-row align-items-center fnt-12">
                                                    <img src={doublebed} alt="Beds" className='m-1' />{prDetails?.propertyBeds} Beds
                                                </div>
                                                <div className="d-flex flex-row align-items-center fnt-12">
                                                    <img src={bath} alt="Baths" className='m-1' />{prDetails?.propertyBaths} Baths
                                                </div>
                                                <div className="d-flex flex-row align-items-center fnt-12">
                                                    <img src={hotelbuilding} alt="Sqft" className='m-1' />{prDetails?.propertySqft}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Messge 2 */}
                {prDetails &&
                    <div className="message-holder mira w-80">
                        <div className="message-bubble">
                            <div className="message-text">
                                Awesome! Here’s my quick read on this home. 
                                <div className="message-fact w-100 d-flex">
                                    <FontAwesomeIcon icon={faInfoCircle} className="me-2 font-size-xxl" />
                                    {propertyFact ? (
                                        <>{propertyFact}</>
                                    ) : (
                                        <ChatLoader />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                }
               
                {/* Message 3 */}
                {!apiError && estimate &&
                    <>
                        <div className="message-holder mira w-80">
                            <div className="message-bubble">
                                <div className="message-text">
                                    {currentCard >= 1 && (estimate?.range?.minimum || estimate?.estimate) ? 
                                        <>
                                            <FontAwesomeIcon icon={faInfoCircle} className="me-2 font-size-xxl" />
                                            Here’s An Estimate:
                                            <div className="message-fact">
                                                <div className="table-responsive">

                                                <table className='w-100 spacing-in-table'>
                                                    <tbody>
                                                        <tr>
                                                            <td className='w-30'>Source</td>
                                                            <td className='w-40'>Estimate</td>
                                                            <td className='w-30'>Sales Range</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <img className="zillow_logo" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/home/zillow-wordmark-blue-rgb-lrg-1@2x.png`} />
                                                            </td>
                                                            <td>
                                                                <div className="message_fraction">
                                                                    <div className="fraction_symbol">{estimate?.estimate} </div>
                                                                </div>
                                                            </td>
                                                            <td>{estimate?.range?.minimum} - {estimate?.range?.maximum}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                                <div className="see_more_container">
                                                    <span className="see_more" onClick={navigateToZillow}>See more</span> details for {propertyAddress} on Zillow. The data provided is as present
                                                    in the Zestimate API.
                                                </div>
                                            </div>

                                        </>
                                    :
                                        <>
                                            {apiError ? 
                                                <>
                                                    No data found
                                                </>
                                            :
                                                <>
                                                    <div className="text-center mt-2">
                                                        <h5>Let me look up a starting point for pricing this property.</h5>
                                                        <img src={homeChatGif} width="80" alt="" />
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                }

                {(currentCard >= 2) &&
                    <>
                        <div className="message-holder mira w-80 drop-animation">
                            <div className="message-bubble">
                                <div className="message-text">
                                 We've got an initial estimate for this property; Let's make it work for you. 
                                </div>
                                <div className="message-fact d-flex gap-10">
                                    <div className="market-placeholder">
                                        <div className="text-placeholder">1</div>
                                    </div>
                                    <span>
                                        <span className="fw-bold">Home Value:</span> We’ll adjust your offer based on the condition and quality of the home, comparable sales, and market conditions.  
                                    </span>
                                </div>
                                <div className="message-fact d-flex gap-10">
                                    <div className="market-placeholder">
                                        <div className="text-placeholder">2</div>
                                    </div>
                                    <span><span className="fw-bold">Lifestyle Fit:</span> We’ll make sure your offer fits your lifestyle and needs.</span>
                                </div>
                            </div>
                        </div>

                        {cmaResponse && cmaResponse?.status === false && 
                            <NoComparable isShow={false} />
                        }

                        <div className="message-holder">
                            <div className="message-button-holder d-flex flex-row w-100 justify-content-end mb-5">
                                {cmaResponse && cmaResponse?.status === true && (
                                    <div
                                        id="get-started-next"
                                        className="button-primary"
                                        data-test-id="cma-response-get-started"
                                        onClick={() => confirmGetStarted()}
                                    >
                                        Get Started
                                    </div>
                                )}

                                {cmaResponse && cmaResponse?.status === false && (
                                    <div
                                        id="return-home"
                                        className="button-primary"
                                        data-test-id="cma-response-return-home"
                                        onClick={() => navigate('/')}
                                    >
                                        Home
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                }


                <div ref={bottomRef} />
            </div>

            <div className="chat-footer d-flex">
                <div className="ms-3">
                    © Zillow, Inc., 2006-2023. Use is subject to Terms of Use
                </div>
            </div>
        </div>
    )
}

export default HomeChat;