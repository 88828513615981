import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import MarketCharts from "../market/charts";
import { scrollToBottom, checkIsLast, checkIsValid, shouldDispatchQuestion, getLastQuestion, getUpdatedResponseBasedOnQtype, checkStandoutQuestion, checkViewFeatureQuestion, checkInspectionQuestion } from '../../utils/Common';
import '../../../assets/market/market.css';
import { getQuestions, propertyMarketRange, getChatResponse, getPropertyImages, saveQnaQuestion, taggedImageList, getSingleImage, getViewSingleImage, getStandoutImages, getInspectionImage } from '../../services/apiService';
import { setRange, clearRangeList, addMarketQuestions, clearMarketQuestionList } from '../../../slices/marketSlice';
import { getCma, setMarketSaved } from '../../../slices/preferenceSlice';
import { useNavigate } from "react-router-dom";
import ChatLoader from "../../services/chat-loader";
import { toast } from "react-toastify";
import { getImagesList, setOfferButton } from '../../../slices/propertySlice';
import { loginData } from '../../../slices/loginSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import homeChatGif from '../../../assets/gifs/home_chat.gif';
import { faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';

// Agent Mira imports
import '../../agent-mira/chat.css';
import DOMPurify from 'dompurify';
// End of imports

import './restbai-prompts.css';

// import thumsup from '../../../assets/svg/thumsup.svg';
// import thumsdown from '../../../assets/svg/thumsdown.svg';
import ChatInput from "../chat-input";
import ShowOffer from "./show-offer";
import PropertyTabs from "../tabs";
import StickyCard from "../sticky-card";

const MarketChat = ({ propertyMarketQuestions, setPropertyMarketQuestions, pageName, triggerAPI, setTriggerAPI  }) => {

    const bottomRef = useRef(null);
    const compareRef = useRef(null);
    const scrollRef = useRef(null);
    const scrollRefStandout = useRef(null);
    const dispatch = useDispatch();
    const hasFetched = useRef(false);
    const videoRef = useRef(null);
    const navigate = useNavigate();
    const propertyId = useSelector((state) => state.propertySlice.propertyId);
    const prevPropertyId = useRef(propertyId);
    const userDetails = useSelector(loginData);
    const cmaDataList = useSelector(getCma);
    const [chartsList, setChartsList] = useState([]);
    const [showIcon, setIcons] = useState(false);
    const [showAnimation, setAnimation] = useState(false);
    const [displayVideo, setdisplayVideo] = useState(false);
    const [selectedCheckbox, setSelectedCheckbox] = useState(0);
    const [comprableProperty, setComparableProperty] = useState(cmaDataList['comparableProperty'][0]);
    const [subjectProperty, setSubjectProperty] = useState(null);
    const [comprablesList, setSubjectCompareList] = useState(null);
    const [compareBtn, setCompareClick] = useState(true);
    const imageList = useSelector(getImagesList);
    const [imageSrc, setImageSrc] = useState(imageList);
    const [compableImagesList, setCompableImagesList] = useState({});
    const [showCompareImage, setCompareImages] = useState(false);
    const [expandedCard, setExpandedCard] = useState(null);
    const [refreshOfferPrice, setRefreshOfferPrice] = useState(null);
    const [showImage, setShowImage] = useState(false);
    const [taggedImgList, setTaggedImgList] = useState([]);
    const [taggedOption, setTaggedOption] = useState(null);
    const [toolTipScore, settoolTipScore] = useState(null);
    const [viewTypeImageList, setViewTypeImageList] = useState([]);
    const [showRestAnimation, setRestAnimation] = useState(false);
    const [showInsAnimation, setInsAnimation] = useState(false);
    const [showQualityAnimation, setQualityAnimation] = useState(false);
    const [standoutImageList, setStandoutImageList] = useState([]);
    const [inspectionImageList, setInspectionImageList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState([]);
    // Agent mira chat variables
    const [userInput, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    // End of variables
   

    const getRange = useCallback(async () => {
        dispatch(clearRangeList());

        try {
            const response = await propertyMarketRange({ "listing_id": propertyId });
            if (response.code === 200) {
                dispatch(setRange(response.response));
                setChartsList(response.response);
            }
        } catch (error) {
            // handle error if necessary
        }
    }, [dispatch, propertyId]);

    const fetchAndStoreImages = useCallback(async (listings) => {
        const results = {};

        for (const listing of listings) {
            const propertyId = listing.ListingId;

            try {
                const response = await getPropertyImagesByListingId(propertyId);
                if (response.code === 200) {
                    if (response?.response?.images?.length > 0) {
                        results[propertyId] = response.response.images;
                    }
                } else {
                    setCompareImages(false);
                }
            } catch (error) {
                toast.warning(`Failed to fetch data for ${propertyId}.`);
            }
        }

        if (Object.keys(results).length > 0) {
            setCompareImages(true);
        }
        else {
            setCompareImages(false);
        }

        setCompableImagesList(results);
    }, []);

    const compareData = useCallback(() => {
        if (comprableProperty === null || subjectProperty === null || comprablesList === null) {
            setComparableProperty(cmaDataList['comparableProperty'][0]);
            setSubjectProperty(cmaDataList['subjectProperty']);
            setSubjectCompareList(cmaDataList['comparableProperty'][0]['Comparables']);
        }
        setCompareClick(true);
        scrollToBottom(compareRef);
    }, [cmaDataList, comprableProperty, comprablesList, subjectProperty]);

    // Function to fetch blob image and update state for View Type
    const getSingleViewImage = useCallback(async (item, index) => {
        try {
            // Call the API to get the image blob
            const response = await getViewSingleImage(propertyId, item.image_id);
            if (response.code === 200) {
                // Convert blob data to URL
                const imageUrl = response.response;

                // Update the taggedImgList with the image URL
                setViewTypeImageList(prevList => {
                    const updatedList = [...prevList];
                    updatedList[index] = {
                        ...updatedList[index],
                        image: imageUrl
                    };
                    return updatedList;
                });
            } else {
                // Update the taggedImgList with an error message if the image is not available
                setViewTypeImageList(prevList => {
                    const updatedList = [...prevList];
                    updatedList[index] = {
                        ...updatedList[index],
                        image: '',
                        apiResponse: "Image not available."
                    };
                    return updatedList;
                });
            }
        } catch (error) {
            throw new Error('Failed to fetch image blob');
        }
    }, [propertyId]);

    // Callback function to fetch the standout images list
    const getViewFeatureList = useCallback(async (question) => {
        setViewTypeImageList([]); // Clear the existing list

        if (question?.response_options?.length > 0) {
            // Initialize the formatted list with an empty image value for each item
            const imageResponse = question.response_options.map(item => ({
                ...item,
                image: ""      // Add a new "image" key with an empty string as the value
            }));

            imageResponse.forEach((item, index) => getSingleViewImage(item, index));

            // Update the state with the transformed response
            setViewTypeImageList(imageResponse);
        }
    }, [setViewTypeImageList, getSingleViewImage]);

    // Function to fetch each standout image and pass the result back via a callback
    const fetchStandoutImage = useCallback(async (item, index, updateImageCallback) => {
        try {
            const response = await getStandoutImages(propertyId, item?.image_id);
            if (response.code === 200) {
                const imageUrl = response.response;
                updateImageCallback({ image: imageUrl });
            } else {
                updateImageCallback({ image: '', apiResponse: "Image not available." });
            }
        } catch (error) {
            console.error(`Failed to load image for image_id: ${item.image_id}`, error);
            updateImageCallback({ image: '', apiResponse: "Failed to load image." });
        }
    }, [propertyId]);

    // Callback function to fetch the standout images list
    const getStandoutList = useCallback(async (question) => {
        setStandoutImageList([]); // Clear the existing list

        if (question.response_options?.length > 0) {
            // Initialize the formatted list with an empty image value for each item
            const formattedList = question.response_options.map(item => ({
                ...item,
                image: ""      // Add a new "image" key with an empty string as the value
            }));

            setStandoutImageList(formattedList); // Set the initial list in state

            // Fetch images for each item, using a callback to update state
            formattedList.forEach((item, index) => {
                fetchStandoutImage(item, index, (updatedImage) => {
                    setStandoutImageList(prevList => {
                        const updatedList = [...prevList];
                        updatedList[index] = {
                            ...updatedList[index],
                            image: updatedImage.image,
                            apiResponse: updatedImage.apiResponse || undefined,
                        };
                        return updatedList;
                    });
                });
            });
        }
    }, [fetchStandoutImage, setStandoutImageList]);

    // Function to fetch each Inspectiondamage image and pass the result back via a callback
    const fetchInspectionImage = useCallback(async (item, index, revertImageCallback) => {
        try {
            const response = await getInspectionImage(propertyId, item?.image_id);
            if (response.code === 200) {
                const imageUrl = response.response;
                revertImageCallback({ image: imageUrl });
            } else {
                revertImageCallback({ image: '', apiResponse: "Image not available." });
            }
        } catch (error) {
            console.error(`Failed to load image for image_id: ${item.image_id}`, error);
            revertImageCallback({ image: '', apiResponse: "Failed to load image." });
        }
    }, [propertyId]);

    // Callback function to fetch the Inspection images list
    const getInspectionList = useCallback(async (question) => {
        setInspectionImageList([]); // Clear the existing list

        if (question.response_options?.length > 0) {
            // Initialize the formatted list with an empty image value for each item
            const inspectionImgJson = question.response_options.map(item => ({
                ...item,
                image: ""      // Add a new "image" key with an empty string as the value
            }));

            setInspectionImageList(inspectionImgJson); // Set the initial list in state

            // Fetch images for each item, using a callback to update state
            inspectionImgJson.forEach((item, index) => {
                fetchInspectionImage(item, index, (updatedImage) => {
                    setInspectionImageList(prevList => {
                        const updatedList = [...prevList];
                        updatedList[index] = {
                            ...updatedList[index],
                            image: updatedImage.image,
                            apiResponse: updatedImage.apiResponse || undefined,
                        };
                        return updatedList;
                    });
                });
            });
        }
    }, [fetchInspectionImage, setInspectionImageList]);

    // Open modal popup
    const openModal = (type, imageList, image_id) => {
        setSelectedImage([]);
        if (type === 1) {
            setSelectedImage(`${process.env.PUBLIC_URL}/images/quality-score/${imageList}.png`);
        }
        else {
            const formattedList = imageList.map(item => ({
                ...item,
                isActive: item.image_id === image_id
            }));
            setSelectedImage(formattedList);
        }
        setIsModalOpen(true);
    };

    // Close modal popup
    const closeModal = useCallback(() => {
        setIsModalOpen(false);
        setSelectedImage([]);
    }, [setIsModalOpen, setSelectedImage]);

    // Tagged images API callback function
    const getTaggedAPIcalls = useCallback(async () => {
        if(triggerAPI){
            setStandoutImageList([]);
            setInspectionImageList([]);
            setViewTypeImageList([]);
        }

        if (standoutImageList.length === 0) {
            const standoutQuestion = checkStandoutQuestion(propertyMarketQuestions);
            if (standoutQuestion) {
                getStandoutList(standoutQuestion);
            }
        }

        if (inspectionImageList.length === 0) {
            const inspectionQuestion = checkInspectionQuestion(propertyMarketQuestions);
            if (inspectionQuestion) {
                getInspectionList(inspectionQuestion);
            }
        }

        if (viewTypeImageList.length === 0) {
            const viewFeatureQlist = checkViewFeatureQuestion(propertyMarketQuestions);
            if (viewFeatureQlist) {
                getViewFeatureList(viewFeatureQlist);
            }
        }

        setTriggerAPI(false);
    }, [propertyMarketQuestions, standoutImageList.length, getStandoutList, viewTypeImageList.length, getViewFeatureList, inspectionImageList.length, getInspectionList, setTriggerAPI, triggerAPI]);

    useEffect(() => {
        // Handle fetch range data and images data
        if ((!hasFetched.current) && propertyMarketQuestions.length > 0) {
            if (getLastQuestion(propertyMarketQuestions)) {
                fetchOfferDetails(true);
            }
            getRange();
            // Handle fetching and storing comparable images
            if (cmaDataList && cmaDataList.comparableProperty?.length > 0) {
                fetchAndStoreImages(cmaDataList.comparableProperty);
            }
            compareData();
            hasFetched.current = true;
        }

        // Handle setting image source if imageList is available
        if (imageList) {
            setImageSrc(imageList);
        }
    }, [propertyMarketQuestions, getRange, compareData, hasFetched, imageList, cmaDataList, fetchAndStoreImages, userDetails, displayVideo]);

    useEffect(() => {
        // Handle property market questions
        if (propertyMarketQuestions) {
            dispatch(clearMarketQuestionList());
            dispatch(addMarketQuestions(propertyMarketQuestions));
            if (checkIsLast(propertyMarketQuestions)) {
                dispatch(setOfferButton(true));
            }
        }
        getTaggedAPIcalls();
    }, [propertyMarketQuestions, dispatch, getTaggedAPIcalls]);

    // Load when propertyId changed
    useEffect(() => {
        if (prevPropertyId.current !== propertyId && hasFetched.current) {
            fetchOfferDetails(true);
            getRange();
            if (cmaDataList && cmaDataList.comparableProperty?.length > 0) {
                fetchAndStoreImages(cmaDataList.comparableProperty);
            }
            // Update the previous propertyId to the current one
            prevPropertyId.current = propertyId;
        }

        if (triggerAPI) { 
            getTaggedAPIcalls();
        }

        const handleEsc = (event) => {
            if (event.key === "Escape") {
                closeModal();
            }
        };

        if (isModalOpen) {
            window.addEventListener("keydown", handleEsc);
        }

        return () => {
            window.removeEventListener("keydown", handleEsc);
        };
    }, [propertyId, cmaDataList, getRange, fetchAndStoreImages, isModalOpen, closeModal, getTaggedAPIcalls, triggerAPI, setTriggerAPI]);

    useEffect(() => {
        const handleKeyDownSkip = (event) => {
            if (videoRef.current) {
                if (event.key === 'ArrowRight') {
                    // Skip forward 5 seconds
                    videoRef.current.currentTime += 5;
                } else if (event.key === 'ArrowLeft') {
                    // Skip backward 5 seconds
                    videoRef.current.currentTime -= 5;
                }
            }
        };

        window.addEventListener('keydown', handleKeyDownSkip);

        return () => {
            window.removeEventListener('keydown', handleKeyDownSkip);
        };
    }, []);

    const selectOption = async (question, option, checkedStatus, quesIndex) => {
        if (option?.isShow === true) {
            setQualityAnimation(true);
            setTimeout(() => {
                setQualityAnimation(false);
            }, 4000);
        }

        if (question.question_id === "first_impression_of_the_property") {
            setRestAnimation(true);
            setTimeout(() => {
                setRestAnimation(false);
            }, 6000);
        }

        if (question.question_id === "propertyMarketInspectionDamage") {
            setInsAnimation(true);
            setTimeout(() => {
                setInsAnimation(false);
            }, 6000);
        }

        if (option?.isAnimation === 1) {
            setIcons(true);
            setTimeout(() => {
                setIcons(false);
            }, 4000);
        }

        if (option?.isAnimation === 2) {
            setAnimation(true);
            setTimeout(() => {
                setAnimation(false);
            }, 4000);
        }

        const updatedMarketQuestions = getUpdatedResponseBasedOnQtype(propertyMarketQuestions, question, option, checkedStatus);

        setPropertyMarketQuestions(updatedMarketQuestions);

        if (!question.hasOwnProperty("response") && !option?.isShow) {
            setLoading(true);
        }

        const checkedOptions = getCheckedResponses(updatedMarketQuestions, question);
        const updatedWithCheckedOptions = updatedMarketQuestions.map(ques =>
            ques.question_id === question.question_id
                ? {
                    ...ques,
                    response: checkedOptions,
                    isSaved: true,
                }
                : ques
        );
        const response = await saveQnaQuestion({ question_id: question.question_id, response: checkedOptions }, pageName, propertyId);

        if (response.code === 200) {
            if ((question.isLast === true || (question.hasOwnProperty("response") && getLastQuestion(updatedWithCheckedOptions)))) {
                fetchOfferDetails(false);
            }

            // To save a key named isSaved so that frontend knows this question is saved.
            setPropertyMarketQuestions(updatedWithCheckedOptions);
            if (!option?.isShow) {
                const questionResponse = await getQuestions(propertyId, pageName);
                if (questionResponse.code === 200) {
                    if (questionResponse?.response?.question_type === "view-features") {
                        getViewFeatureList(questionResponse?.response);
                    }

                    if (questionResponse?.response?.question_type === "standout-features") {
                        getStandoutList(questionResponse?.response)
                    }

                    if (questionResponse?.response?.question_type === "inspection-features") {
                        getInspectionList(questionResponse?.response)
                    }

                    // Update property market questions if needed
                    if (shouldDispatchQuestion(questionResponse.response, updatedWithCheckedOptions)) {
                        setPropertyMarketQuestions(prevQuestions => [
                            ...prevQuestions,
                            questionResponse.response
                        ]);
                    }

                    if (questionResponse?.response?.question_type !== "quality-specific-area" && questionResponse?.response?.question_type !== "quality-score" && !questionResponse?.response?.response) {
                        scrollToBottom(bottomRef);
                    }

                    // If there are no response options, save a default response
                    // if (((questionResponse?.response?.response_options?.length === 0 && !questionResponse?.response?.hasOwnProperty('response')) || (questionResponse?.response?.question_type === 'standout-features') || (questionResponse?.response?.question_type === 'quality-specific-area') || (questionResponse?.response?.question_type === 'quality-score'))) {
                    //     setTimeout(async () => {
                    //         dispatch(setMarketSaved(true));
                    //         await saveDefaultResponse(questionResponse.response.question_id);
                    //     }, 5000);
                    // }

                    if (question.isLast !== true) {
                        setLoading(false);
                    }
                }
                else {
                    setLoading(false);
                }
            }
        }
        else {
            setLoading(false);
        }
    }

    const saveDefaultResponse = async (questionId) => {
        setLoading(true);
        try {
            // Save a default response
            const response = await saveQnaQuestion({ question_id: questionId, response: ["1"] }, pageName, propertyId);
            if (response.code === 200) {
                // Fetch updated questions after saving
                if (checkIsLast(propertyMarketQuestions)) {
                    const updatedQuestions = propertyMarketQuestions.map(item =>
                        item.isLast === true ? { ...item, response: ['1'] } : item
                    );
                    setPropertyMarketQuestions(updatedQuestions);
                } else {

                    const apiResponse = await getQuestions(propertyId, pageName);
                    if (apiResponse.code === 200) {
                        if (apiResponse?.response?.question_type === "standout-features") {
                            getStandoutList(apiResponse?.response)
                        }

                        if (apiResponse?.response?.question_type === "inspection-features") {
                            getInspectionList(apiResponse?.response)
                        }

                        if (shouldDispatchQuestion(apiResponse.response, propertyMarketQuestions)) {
                            setPropertyMarketQuestions(prevQuestions => [
                                ...prevQuestions,
                                apiResponse.response // Add the new question response to state
                            ]);
                        }


                        if (apiResponse.response.isLast === true) {
                            setLoading(false);
                            await saveDefaultResponse(apiResponse.response.question_id);

                            setTimeout(async () => {
                                dispatch(setMarketSaved(true));
                                fetchOfferDetails(false);
                            }, 8000);
                        }

                        // if (((apiResponse?.response?.response_options?.length === 0 && !apiResponse?.response?.hasOwnProperty('response')) || (apiResponse?.response?.question_type === 'standout-features') || (apiResponse?.response?.question_type === 'quality-specific-area') || (apiResponse?.response?.question_type === 'quality-score'))) {
                        //     setTimeout(async () => {
                        //         dispatch(setMarketSaved(true));
                        //         await saveDefaultResponse(apiResponse.response.question_id);
                        //     }, 8000);
                        // }
                        if (apiResponse.response?.question_type !== "quality-specific-area" && apiResponse.response?.question_type !== "quality-score") {
                            scrollToBottom(bottomRef);
                        }
                    }
                    else {
                        toast.error("Failed to fetch updated questions")
                    }
                }
            }
        }
        catch (error) {
            toast.error("Unable to save the question.")
        }
        setLoading(false);
    }

    const getCheckedResponses = (allQuestions, question) => {
        const selectedQuestion = allQuestions.find(ques => ques.question_id === question.question_id);
        const checkedOptions = selectedQuestion.response_options.filter(option => option.isChecked === true);
        const payload = []
        checkedOptions.forEach(option => {
            payload.push(option.option_id)
        })
        return payload;
    }

    const fetchOfferDetails = async (payload) => {
        setLoading(false);
        const dataToSend = { isHistory: payload, timestamp: new Date().toLocaleTimeString() };
        // Send data to the child component
        setRefreshOfferPrice(dataToSend);
    };

    const handleCheckboxChange = (event, index, list) => {
        setSelectedCheckbox(index === selectedCheckbox ? null : index);
        setCompareClick(false);
        setComparableProperty(list);
        setSubjectProperty(cmaDataList['subjectProperty']);
        setSubjectCompareList(cmaDataList['comparableProperty'][index]['Comparables']);
        compareData();
    };

    const getImageSrc = (list) => {
        if (Object.keys(compableImagesList)?.length > 0 && compableImagesList[list]?.length > 0) {
            return `data:image/jpeg;base64,${compableImagesList[list][0]}`;
        }
        return null;
    };

    const getPropertyImagesByListingId = async (mls_id) => {
        try {
            const response = await getPropertyImages({ "mls_id": mls_id })
            return response;
        }
        catch (error) {
            throw new Error('Failed to fetch images');
        }
    };

    const expandMoreLess = (index) => {
        setExpandedCard(expandedCard === index ? null : index);
    };

    const getTaggedImagesIds = async (option) => {
        setTaggedOption(option.option_text);
        settoolTipScore(option.tooltip_text);
        setTaggedImgList([]);
        try {
            // Save a default response
            const response = await taggedImageList(propertyId, option.option_text.toLowerCase());
            if (response.code === 200) {
                // Transform the response into the desired format
                const formattedResponse = response.response.map(image_id => ({
                    image_id,
                    image: ""
                }));

                formattedResponse.forEach((item, index) => fetchBlobImage(item, index));

                // Update the state with the transformed response
                setTaggedImgList(formattedResponse);
                setShowImage(true);
            }
        }
        catch (error) {
            throw new Error('Failed to fetch images');
        }
    }

    // Function to fetch blob image and update state
    const fetchBlobImage = async (item, index) => {
        try {
            // Call the API to get the image blob
            const response = await getSingleImage(propertyId, item.image_id);
            if (response.code === 200) {
                // Convert blob data to URL
                const imageUrl = response.response;

                // Update the taggedImgList with the image URL
                setTaggedImgList(prevList => {
                    const updatedList = [...prevList];
                    updatedList[index] = {
                        ...updatedList[index],
                        image: imageUrl
                    };
                    return updatedList;
                });
            }
            else {
                // Update the taggedImgList with the image URL
                setTaggedImgList(prevList => {
                    const updatedList = [...prevList];
                    updatedList[index] = {
                        ...updatedList[index],
                        image: '',
                        apiResponse: "Image not available."
                    };
                    return updatedList;
                });
            }
        }
        catch (error) {
            throw new Error('Failed to fetch image blob');
        }
    };

    const scrollLeft = (ref) => {
        if (ref.current) {
            ref.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };

    const scrollRight = (ref) => {
        if (ref.current) {
            ref.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };

    // ===============================================================================================================
    // ===============================================================================================================
    // ================================Agent Mira Chat Functions =====================================================
    // ===============================================================================================================
    // ===============================================================================================================

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && userInput.trim() !== '') {
            saveChatAndGetResponse('user', userInput);
        }
    };

    const getChat = () => {
        if (userInput.trim() !== '') {
            saveChatAndGetResponse('user', userInput);
        }
    };

    const getChatSuggestions = (suggestion) => {
        saveChatAndGetResponse('user', suggestion);
    };

    const handleChatResponse = (responseMessage) => {
        const updateChat = { sender: "mira", message: responseMessage, chat: true };
        setPropertyMarketQuestions(prevState => [...prevState, updateChat]);
    };

    const saveChatAndGetResponse = async (type, message) => {
        const errorMessage = "There is some issue with accessing our AI engine, can you please ask your question again.";
        setInput('');
        const newMessage = { sender: type, message: message, chat: true };
        setPropertyMarketQuestions(prevState => [...prevState, newMessage]);

        try {
            setLoading(true);
            const chat_response = await getChatResponse({ "user_str": message, "page": "property_market" }, propertyId);

            if (chat_response.code === 200) {
                if (chat_response.response.mira_chat_response) {
                    handleChatResponse(chat_response.response.mira_chat_response);
                }
                else {
                    handleChatResponse(errorMessage);
                }
            }
            else {
                handleChatResponse(errorMessage);
            }
        }
        catch (error) {
            const updateChat = { sender: "mira", message: errorMessage, chat: true };
            setPropertyMarketQuestions(prevState => [...prevState, updateChat]);
        }
        finally {
            // setLoading(false);
        }

        if (checkIsValid(propertyMarketQuestions)) {
            const getLatestQuestion = await getQuestions(propertyId, pageName);
            if (getLatestQuestion.code === 200) {
                setPropertyMarketQuestions(prevState => {
                    const marketQues = [...prevState];
                    const questionsWithBothKeys = marketQues.filter(question => question.question_id === getLatestQuestion.response.question_id);

                    if (questionsWithBothKeys.length > 0) {
                        // Remove existing question with the same question_id
                        const index = marketQues.findIndex(question => question.question_id === getLatestQuestion.response.question_id);
                        marketQues.splice(index, 1);
                    }

                    // Add new response to the end of the array
                    marketQues.push(getLatestQuestion.response);

                    return marketQues;
                });
                setLoading(false);
            }
            else {
                setLoading(false);
            }
        }
        else {
            setLoading(false);
        }
    };

    const containsLearnMore = (text) => {
        return text.toLowerCase().includes("learn more");
    };

    const showVideo = () => {
        setdisplayVideo(!displayVideo);
        if (displayVideo && videoRef.current) {
            videoRef.current.pause();
        }
        else {
            videoRef.current.play().catch((error) => { });
        }
    };

    const transformText = (text) => {
        const searchTerm = "learn more";
        const lowerCaseText = text.toLowerCase();

        // Find the index of the "learn more" phrase
        const index = lowerCaseText.indexOf(searchTerm.toLowerCase());

        if (index === -1) return text;

        const beforeText = text.slice(0, index);
        const matchedText = text.slice(index, index + searchTerm.length);
        const afterText = text.slice(index + searchTerm.length);

        return (
            <>
                {beforeText}
                <a id="learn-more" href="#!" onClick={showVideo}>
                    {matchedText}
                </a>
                {afterText}
            </>
        );
    };

    const removeHtmlTags = (str) => {
        return str.replace(/<\/?[^>]+(>|$)/g, "");
    }

    return (
        <>
            <div className="container_main">
                <div className="part right-section">
                    <div className="chat-container">
                        <div className="chat-header fixed-button-top">
                            <PropertyTabs />
                        </div>

                        <div className="chat-window pt-0">
                            <div className="content cs-mr">
                                {propertyMarketQuestions?.length > 0 &&
                                    <>
                                        {propertyMarketQuestions.map((ques, index) => (
                                            <>
                                                {ques?.chat === true ?
                                                    <>
                                                        {/* Agent mira chat list */}
                                                        {ques?.sender === 'mira' &&
                                                            <div className="message-holder mira-chat ml-85 mr-85" id={index} data-test-id={`mira-response-${index}`} key={index}>
                                                                <div className="message-bubble">
                                                                    <div className={`label ${index === 0 ? 'agent-label hm-top-65' : 'd-none'}`}>
                                                                        Agent Mira
                                                                    </div>
                                                                    <div className="message-text">
                                                                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ques.message) }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                        {ques?.sender === 'user' &&
                                                            <div className="message-holder user-block ml-85" id={index} data-test-id={`user-response-${index}`} data-content={`${atob(userDetails?.firstName).charAt(0).toUpperCase()}${atob(userDetails?.lastName).charAt(0).toUpperCase()}`} key={index}>
                                                                <div className="message-bubble reply-bubble">

                                                                    <div className="message-text d-flex js-start">
                                                                        <div className="mb-2">
                                                                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ques.message) }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {/* Agent Mira chat ends */}
                                                    </>
                                                    :
                                                    <>
                                                        {ques?.question_type &&
                                                            <>
                                                                {/* QNA Questions */}
                                                                <div className="message-holder mira-chat ml-85 mr-85" id={ques?.question_id} data-test-id={`question-block-${ques?.question_id}`} key={ques?.question_id}>
                                                                    <div className="message-bubble">
                                                                        <div className={`label ${index === 0 ? 'd-flex fw-bold' : 'd-none'}`}>
                                                                            Agent Mira
                                                                        </div>
                                                                        <div className="message-text">
                                                                            {ques.question_type !== "video" && ques.question_type !== "chart-type" &&
                                                                                <>
                                                                                    {index === 0 ? (
                                                                                        <div className="w-100 d-flex">
                                                                                            <FontAwesomeIcon icon={faInfoCircle} className="me-2 font-size-xxl" />
                                                                                            <span>
                                                                                                <span className="fw-bold">Let’s Fine-Tune the Price</span><br />
                                                                                                {ques?.question_text}
                                                                                            </span>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <span className={`${ques?.question_id === 'first_impression_of_the_property' ? 'fw-bold' : ''}`}>
                                                                                            {ques?.question_text}
                                                                                        </span>
                                                                                    )}
                                                                                </>
                                                                            }
                                                                            {ques.question_type === "video" &&
                                                                                <>
                                                                                    {containsLearnMore(ques?.question_text) ? (
                                                                                        <span>
                                                                                            <span className="fw-bold">Check Out Recent Sales</span><br />
                                                                                            {transformText(ques?.question_text)}
                                                                                        </span>
                                                                                    ) : (
                                                                                        <span></span>
                                                                                    )}
                                                                                </>
                                                                            }
                                                                            {ques.question_type === "chart-type" &&
                                                                                <>
                                                                                    <span>
                                                                                        <span className="fw-bold">Fine-Tuning the Offer Price</span><br />
                                                                                        {ques?.question_text}
                                                                                    </span>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {chartsList && Object.keys(chartsList).length > 0 && chartsList?.insights !== '' && ques.question_type === "chart-type" &&
                                                                    <>
                                                                        <div className="message-holder mira-chat ml-85 mr-85">
                                                                            <div className="message-bubble">
                                                                                <div className="message-text">
                                                                                    <FontAwesomeIcon icon={faInfoCircle} className="me-2 font-size-xxl" />
                                                                                    {chartsList?.insights}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }

                                                                {(index === (propertyMarketQuestions.length - 1)) && <div ref={bottomRef} />}

                                                                {ques?.question_type !== "text-only" && ques?.question_type !== "inspection-features" && ques?.question_type !== "standout-features" && ques?.question_type !== "quality-specific-area" &&
                                                                    <div className="message-holder sub-card ml-85 mr-85">
                                                                        <div className="message-bubble w-90 bg-none p-0 mt-2 ps-relative">
                                                                            {(ques?.question_type === "single-select" || ques?.question_type === "quality-score") && (
                                                                                <div className="">
                                                                                    {ques?.response_options?.length > 0 && (
                                                                                        <>
                                                                                            <div className={` ${loading ? 'disable' : ''}`}>
                                                                                                <div className="commute-checkbox-parent">
                                                                                                    {ques.response_options.map((option) => (
                                                                                                        <>
                                                                                                            {(option?.isChecked === true) ?
                                                                                                                <>
                                                                                                                    <div className="commute-checkbox" id={option?.option_id} data-test-id={`option-checked${option.option_id}`}  onClick={() => selectOption(ques, option, false, index)}>
                                                                                                                        <div className="check">
                                                                                                                            <img className="icon" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/preference/screen1/icon.svg`} />
                                                                                                                        </div>
                                                                                                                        <div className="commute-friendly">{option?.option_text}</div>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                                :
                                                                                                                <div className="checkbox-container" data-test-id={`option-unchecked${option.option_id}`} onClick={() => selectOption(ques, option, true, index)}>
                                                                                                                    <div className="checkbox">
                                                                                                                        <div className="checkbox-base"></div>
                                                                                                                    </div>
                                                                                                                    <div className="kid-friendly">{option?.option_text}</div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </>
                                                                                                    ))}
                                                                                                </div>

                                                                                                {ques.response_options.map((option) => (
                                                                                                    <>
                                                                                                        {(option?.isChecked === true && option?.option_text?.toLowerCase() === 'no') &&
                                                                                                            <p className="mt-3 mb-0 w-100">
                                                                                                                It’s always best to check out the place in person before making an offer, but we can definitely help you get started now. You can always come back with more details after your visit.
                                                                                                            </p>
                                                                                                        }
                                                                                                    </>
                                                                                                ))}
                                                                                            </div>

                                                                                            {ques.response_options.map((option) => (
                                                                                                <>
                                                                                                    {option?.isAnimation === 2 && showAnimation &&
                                                                                                        <section>
                                                                                                            <img className="z-top  pre_icon" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/icons/655-1@2x.png`} />
                                                                                                            <img className="z-top  pre_icon1" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/icons/655-2@2x.png`} />
                                                                                                            <img className="z-top  pre_icon2" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/icons/655-3@2x.png`} />
                                                                                                            <img className="z-top  pre_icon3" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/icons/655-4@2x.png`} />
                                                                                                            <img className="z-top  pre_icon4" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/icons/655-4@2x.png`} />
                                                                                                            <img className="z-top  pre_icon5" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/icons/655-4@2x.png`} />
                                                                                                        </section>
                                                                                                    }

                                                                                                    {option?.isAnimation === 1 && showIcon &&
                                                                                                        <section>
                                                                                                            <img className="z-top  pre_icon" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/icons/clap.gif`} />
                                                                                                            <img className="z-top  pre_icon1" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/icons/clap.gif`} />
                                                                                                            <img className="z-top  pre_icon2" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/icons/clap.gif`} />
                                                                                                            <img className="z-top  pre_icon3" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/icons/clap.gif`} />
                                                                                                            <img className="z-top  pre_icon4" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/icons/clap.gif`} />
                                                                                                            <img className="z-top  pre_icon5" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/icons/clap.gif`} />
                                                                                                        </section>
                                                                                                    }
                                                                                                </>
                                                                                            ))}
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            )}

                                                                            {ques.question_type === "multiple-select" && (
                                                                                <div className="">
                                                                                    {ques?.response_options?.length > 0 && (
                                                                                        <div className={` ${loading ? 'disable' : ''}`}>
                                                                                            <div className="commute-checkbox-parent">
                                                                                                {ques.response_options.map((option) => (
                                                                                                    <>
                                                                                                        {(option?.isChecked === true) ?
                                                                                                            <div className="commute-checkbox" id={option?.option_id} data-test-id={`option-checked${option.option_id}`} onClick={() => selectOption(ques, option, false, index)}>
                                                                                                                <div className="check">
                                                                                                                    <img className="icon" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/preference/screen1/icon.svg`} />
                                                                                                                </div>
                                                                                                                <div className="commute-friendly">{option?.option_text}</div>
                                                                                                            </div>
                                                                                                            :
                                                                                                            <div className="checkbox-container" id={option?.option_id} data-test-id={`option-unchecked${option.option_id}`} onClick={() => selectOption(ques, option, true, index)}>
                                                                                                                <div className="checkbox">
                                                                                                                    <div className="checkbox-base"></div>
                                                                                                                </div>
                                                                                                                <div className="kid-friendly">{option?.option_text}</div>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </>
                                                                                                ))}
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )}

                                                                            {ques.question_type === "chart-type" && (
                                                                                <>
                                                                                    {(ques.question_type === "chart-type") ?
                                                                                        <>
                                                                                            <MarketCharts chartsList={chartsList} />
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <ChatLoader />
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            )}

                                                                            {ques.question_type === "video" && (
                                                                                <>
                                                                                    <div className={`scfv_frame_wrapper16 w-90 ${displayVideo === false ? 'd-none' : ''}`}>
                                                                                        <div className="scfv_book_parent w-100">
                                                                                            <video height="330" controls ref={videoRef}>
                                                                                                <source src={`${process.env.PUBLIC_URL}/video/intro.mp4`} type="video/mp4" />
                                                                                                Your browser does not support the video tag.
                                                                                            </video>
                                                                                        </div>
                                                                                    </div>

                                                                                    {(Object.keys(cmaDataList)?.length > 0) &&
                                                                                        <>
                                                                                            <div className="chat_mira_child mt-4 mb-0">
                                                                                                <div className="frame-parent17">
                                                                                                    <h5 className="fw-bold ms-2">Here are some similar properties, you can select any one of these for comparison.</h5>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="chat_mira_child card-width">
                                                                                                {cmaDataList['comparableProperty'].map((list, index) => (

                                                                                                    <label className="custom-label-comparable" key={index}>
                                                                                                        <div className={`card-wrapper ${(selectedCheckbox === index) ? 'checked-card' : ''} `} key={list.ListingId}>
                                                                                                            <div className="h-10">
                                                                                                                <input
                                                                                                                    className="form-check-input custom-radio-btn mt-0 mb-1"
                                                                                                                    type="radio"
                                                                                                                    name="comparables"
                                                                                                                    value={index}
                                                                                                                    checked={(selectedCheckbox === index)}
                                                                                                                    onChange={(event) => handleCheckboxChange(event, index, list)}
                                                                                                                    aria-label=""
                                                                                                                    id={`comprable-${index}`} data-test-id={`checked-comprable${index}`} 
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div className={`card position-relative justify-content-between ${(selectedCheckbox === index) ? 'checked-card' : ''} `}>
                                                                                                                <div className="card-header">
                                                                                                                    {showCompareImage && Object.keys(compableImagesList)?.length > 0 &&
                                                                                                                        <>
                                                                                                                            {getImageSrc(list.ListingId) ? (
                                                                                                                                <img src={getImageSrc(list.ListingId)} loading="lazy" className="card-img-top" alt={`Listing ${list.ListingId}`} />
                                                                                                                            ) : (
                                                                                                                                <div className="mt-5 text-center">
                                                                                                                                    <ChatLoader />
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        </>
                                                                                                                    }
                                                                                                                </div>

                                                                                                                <div className="card-body">
                                                                                                                    <h5 className="card-title">{list?.UnparsedAddress}</h5>
                                                                                                                    <p className="card-text">Sold for {list?.ClosePrice} in {list?.CloseDate}</p>

                                                                                                                    <div className={`additional-details ${expandedCard === index ? 'd-block' : 'd-none'}`}>
                                                                                                                        <p className="card-text">Build in year- {list?.YearBuilt}</p>
                                                                                                                        <p className="card-text">Bed/Baths- {list?.BedroomsTotal} / {list?.BathroomsTotalDecimal}</p>
                                                                                                                        <p className="card-text">Living Area- {list?.LivingArea}</p>
                                                                                                                    </div>

                                                                                                                    <div className="view-more mt-1" onClick={() => expandMoreLess(index)}>
                                                                                                                        {expandedCard === index ? 'Show less' : 'View more'}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </label>
                                                                                                ))}
                                                                                            </div>

                                                                                            {(comprableProperty && compareBtn) &&
                                                                                                <>
                                                                                                    <div className="chat_mira_child card-width w-100">
                                                                                                        <div>
                                                                                                            <div className="card h-100">
                                                                                                                <div className="card-body w-100">
                                                                                                                    <h5 className="p-2">
                                                                                                                        <b>Compare houses</b>
                                                                                                                    </h5>
                                                                                                                    <div className="table-responsive" ref={compareRef}>
                                                                                                                        <table className="table table-bordered comprables">
                                                                                                                            <thead>
                                                                                                                                <tr>
                                                                                                                                    <th scope="col">Attributes</th>
                                                                                                                                    <th scope="col">
                                                                                                                                        {imageSrc ? (
                                                                                                                                            <img className="card-img-top" loading="lazy" alt="" src={`data:image/jpeg;base64,${imageSrc}`} />
                                                                                                                                        ) : (
                                                                                                                                            <>
                                                                                                                                                <div className="card-img-top bg-gray" />
                                                                                                                                            </>
                                                                                                                                        )}
                                                                                                                                    </th>
                                                                                                                                    <th scope="col">
                                                                                                                                        {showCompareImage && Object.keys(compableImagesList)?.length > 0 &&
                                                                                                                                            <>
                                                                                                                                                {getImageSrc(comprableProperty?.ListingId) ? (
                                                                                                                                                    <img src={getImageSrc(comprableProperty?.ListingId)} loading="lazy" className="card-img-top" alt={`Listing ${comprableProperty?.ListingId}`} />
                                                                                                                                                ) : (
                                                                                                                                                    <div className="mt-5 text-center">
                                                                                                                                                        <ChatLoader />
                                                                                                                                                    </div>
                                                                                                                                                )}
                                                                                                                                            </>
                                                                                                                                        }
                                                                                                                                    </th>
                                                                                                                                    {/* <th scope="col">Adjustments</th> */}
                                                                                                                                </tr>
                                                                                                                            </thead>
                                                                                                                            <tbody>
                                                                                                                                <tr>
                                                                                                                                    <td>Address</td>
                                                                                                                                    <td>{subjectProperty?.UnparsedAddress}</td>
                                                                                                                                    <td>
                                                                                                                                        {comprableProperty?.UnparsedAddress}
                                                                                                                                    </td>
                                                                                                                                    {/* <td></td> */}
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>List Price</td>
                                                                                                                                    <td>{subjectProperty?.ListPrice.toLocaleString()}</td>
                                                                                                                                    <td>
                                                                                                                                        {comprableProperty?.ListPrice}
                                                                                                                                    </td>
                                                                                                                                    {/* <td className={`${String(comprablesList?.listPrice).startsWith('-') ? 'negative-font-color' : 'positive-font-color'}`}>{comprablesList?.listPrice}</td> */}
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>List Price/Sq Feet</td>
                                                                                                                                    <td>
                                                                                                                                        {subjectProperty?.['ListPrice/SqFeet']}
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        {comprableProperty?.['ListPrice/SqFeet']}
                                                                                                                                    </td>
                                                                                                                                    {/* <td>
                                                                                                                                    </td> */}
                                                                                                                                </tr>

                                                                                                                                <tr>
                                                                                                                                    <td>Days on Market</td>
                                                                                                                                    <td>{subjectProperty?.DaysOnMarket} days</td>
                                                                                                                                    <td>
                                                                                                                                        {comprableProperty?.DaysOnMarket} days
                                                                                                                                    </td>
                                                                                                                                    {/* <td></td> */}
                                                                                                                                </tr>

                                                                                                                                <tr>
                                                                                                                                    <td>Beds/Baths</td>
                                                                                                                                    <td>
                                                                                                                                        {subjectProperty?.BedroomsTotal} beds/{subjectProperty?.BathroomsTotalDecimal} baths
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        {comprableProperty?.BedroomsTotal} beds/{comprableProperty?.BathroomsTotalDecimal} baths
                                                                                                                                    </td>
                                                                                                                                    {/* <td className={`${String(comprablesList?.bedBath).startsWith('-') ? 'negative-font-color' : 'positive-font-color'}`}>{comprablesList?.bedBath}</td> */}
                                                                                                                                </tr>

                                                                                                                                <tr>
                                                                                                                                    <td>Living Area</td>
                                                                                                                                    <td>
                                                                                                                                        {subjectProperty?.LivingArea.toLocaleString()}
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        {comprableProperty?.LivingArea.toLocaleString()}
                                                                                                                                    </td>
                                                                                                                                    {/* <td rowSpan="2" className={`${String(comprablesList?.CombineArea).startsWith('-') ? 'negative-font-color' : 'positive-font-color'}`}>{comprablesList?.CombineArea}</td> */}
                                                                                                                                </tr>

                                                                                                                                <tr>
                                                                                                                                    <td>Lot Size</td>
                                                                                                                                    <td>
                                                                                                                                        {subjectProperty?.LotSizeSquareFeet}
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        {comprableProperty?.LotSizeSquareFeet}
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>Year Built</td>
                                                                                                                                    <td>
                                                                                                                                        {subjectProperty?.YearBuilt}
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        {comprableProperty?.YearBuilt}
                                                                                                                                    </td>
                                                                                                                                    {/* <td></td> */}
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>Sales Price</td>
                                                                                                                                    <td>
                                                                                                                                        {subjectProperty?.ClosePrice}
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        {comprableProperty?.ClosePrice}
                                                                                                                                    </td>
                                                                                                                                    {/* <td></td> */}
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>Sales Price/Sq Feet</td>
                                                                                                                                    <td>
                                                                                                                                        {subjectProperty?.['ClosePrice/SqFeet']}
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        {comprableProperty?.['ClosePrice/SqFeet']}
                                                                                                                                    </td>
                                                                                                                                    {/* <td></td> */}
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>Sold On Date</td>
                                                                                                                                    <td>
                                                                                                                                        {subjectProperty?.CloseDate}
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        {comprableProperty?.CloseDate}
                                                                                                                                    </td>
                                                                                                                                    {/* <td className={`${String(comprablesList?.CloseDate).startsWith('-') ? 'negative-font-color' : 'positive-font-color'}`}>{comprablesList?.CloseDate}</td> */}
                                                                                                                                </tr>
                                                                                                                            </tbody>
                                                                                                                        </table>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {ques?.question_type === "standout-features" &&
                                                                    <>
                                                                        <div className={`message-holder sub-card ml-85 mr-85 rounded-2 p-2 mt-0 ${showRestAnimation === true ? 'bg-green mt-3' : ''}`}>
                                                                            <div className="message-bubble w-90 bg-none p-0 ps-relative">
                                                                                {showRestAnimation === true &&
                                                                                    <>
                                                                                        <div className="loader-text text-center p-2">
                                                                                            <h6>Awesome Work!</h6>
                                                                                            <h6>Hold on while I fetch the top standout features of the property for you, based on what we have so far.</h6>
                                                                                            <img src={homeChatGif} width="80" alt="" />
                                                                                        </div>
                                                                                    </>
                                                                                }

                                                                                {showRestAnimation === false && <>
                                                                                    <div className="d-flex flex-row flex-wrap">

                                                                                        {standoutImageList?.length > 0 && (
                                                                                            <>
                                                                                                <div className="standout-container">
                                                                                                    {standoutImageList?.length > 1 &&<>
                                                                                                        <button className="scroll-button left" onClick={()=> scrollLeft(scrollRefStandout)}><FontAwesomeIcon icon={faChevronLeft} /></button>
                                                                                                    </>}
                                                                                                    <div className="feature-container" ref={scrollRefStandout}>
                                                                                                        {standoutImageList.map(card => (
                                                                                                            <div className="feature" key={card?.image_id}>
                                                                                                                {card?.image !== '' ? (
                                                                                                                    <img className="rounded-2 cursor-pointer" src={`data:image/jpeg;base64,${card?.image}`} alt={` ${card?.image_id}`} onClick={() => openModal(0, standoutImageList, card?.image_id)} />
                                                                                                                ) : (
                                                                                                                    <div className="gray-standout-bg">
                                                                                                                        <div className="text-center">
                                                                                                                            {card?.apiResponse ? (
                                                                                                                                <div>{card?.apiResponse}</div>
                                                                                                                            ) : (
                                                                                                                                <ChatLoader />
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                                <div className="card-label mt-2">{card?.option_text}</div>
                                                                                                            </div>
                                                                                                        ))}
                                                                                                    </div>
                                                                                                    {standoutImageList?.length > 1 &&<>
                                                                                                        <button className="scroll-button right" onClick={()=> scrollRight(scrollRefStandout)}><FontAwesomeIcon icon={faChevronRight} /></button>
                                                                                                    </>}
                                                                                                </div>
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                </>}

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }

                                                                {ques?.question_type === "view-features" &&
                                                                    <>
                                                                        <div className="message-holder sub-card ml-85 mr-85 rounded-2 p-2 mt-v-0">
                                                                            <div className="message-bubble w-90 bg-none p-0 ps-relative">
                                                                                {/* If view type exist */}
                                                                                {viewTypeImageList?.length > 0 &&
                                                                                    <>
                                                                                        <div className="d-flex flex-row flex-wrap justify-content-center">
                                                                                            <div id={`carouselViewType${ques?.question_id}`} className="carousel slide" data-bs-ride="carousel">
                                                                                                {viewTypeImageList?.length > 1 && <>
                                                                                                    <div className="carousel-indicators">
                                                                                                        {viewTypeImageList.map((option, index) => (
                                                                                                            <button key={index} type="button" data-bs-target={`#carouselViewType${ques?.question_id}`} data-bs-slide-to={index} className={index === 0 ? 'active' : ''} aria-current={index === 0 ? 'true' : undefined} aria-label={`Slide ${index + 1}`} ></button>
                                                                                                        ))}
                                                                                                    </div>
                                                                                                </>}

                                                                                                <div className="carousel-inner">
                                                                                                    {viewTypeImageList.map((list, opIndex) => (
                                                                                                        <>
                                                                                                            <div key={opIndex} className={`carousel-item ${opIndex === 0 ? 'active' : ''}`}>
                                                                                                                {list?.image !== '' &&
                                                                                                                    <>
                                                                                                                        <img src={`data:image/jpeg;base64,${list?.image}`} onClick={() => openModal(0, viewTypeImageList, list?.image_id)} className="rounded-2 cursor-pointer" width="640" height="340" alt={`Slide ${opIndex + 1}`} />
                                                                                                                    </>
                                                                                                                }
                                                                                                                {(!list?.image || list?.image === '') &&
                                                                                                                    <>
                                                                                                                        <div className="gray-image-bg">
                                                                                                                            <div className="text-center">
                                                                                                                                {list?.apiResponse && <div>{list?.apiResponse}</div>}

                                                                                                                                {!list?.apiResponse && <ChatLoader />}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </>
                                                                                                    ))}
                                                                                                </div>

                                                                                                {viewTypeImageList?.length > 1 && <>
                                                                                                    <button className="carousel-control-prev" type="button" data-bs-target={`#carouselViewType${ques?.question_id}`} data-bs-slide="prev">
                                                                                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                                                        <span className="visually-hidden">Previous</span>
                                                                                                    </button>
                                                                                                    <button className="carousel-control-next" type="button" data-bs-target={`#carouselViewType${ques?.question_id}`} data-bs-slide="next">
                                                                                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                                                                        <span className="visually-hidden">Next</span>
                                                                                                    </button>
                                                                                                </>}
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }

                                                                {/* Inspection damage question */}
                                                                {ques?.question_type === "inspection-features" &&
                                                                    <>
                                                                        <div className={`message-holder sub-card ml-85 mr-85 rounded-2 p-2 mt-0 ${showInsAnimation === true ? 'bg-green mt-3' : ''}`}>
                                                                            <div className="message-bubble w-90 bg-none p-0 ps-relative">
                                                                                {showInsAnimation === true &&
                                                                                    <>
                                                                                        <div className="loader-text text-center p-2">
                                                                                            <h6>Awesome Work!</h6>
                                                                                            <h6>Hold on while I fetch the top Inspection images of the property for you, based on what we have so far.</h6>
                                                                                            <img src={homeChatGif} width="80" alt="" />
                                                                                        </div>
                                                                                    </>
                                                                                }

                                                                                {showInsAnimation === false && <>
                                                                                    <div className="d-flex flex-row flex-wrap">

                                                                                        {inspectionImageList?.length > 0 && (
                                                                                            <>
                                                                                                <div className="standout-container">
                                                                                                    {inspectionImageList?.length > 1 &&<>
                                                                                                        <button className="scroll-button left" onClick={()=> scrollLeft(scrollRef)}><FontAwesomeIcon icon={faChevronLeft} /></button>
                                                                                                    </>}
                                                                                                    <div className="feature-container" ref={scrollRef}>
                                                                                                        {inspectionImageList.map(card => (
                                                                                                            <div className="feature" key={card?.image_id}>
                                                                                                                {card?.image !== '' ? (
                                                                                                                    <img className="rounded-2 cursor-pointer" src={`data:image/jpeg;base64,${card?.image}`} alt={` ${card?.image_id}`} onClick={() => openModal(0, inspectionImageList, card?.image_id)} />
                                                                                                                ) : (
                                                                                                                    <div className="gray-standout-bg">
                                                                                                                        <div className="text-center">
                                                                                                                            {card?.apiResponse ? (
                                                                                                                                <div>{card?.apiResponse}</div>
                                                                                                                            ) : (
                                                                                                                                <ChatLoader />
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                                <div className="card-label mt-2">{card?.option_text}</div>
                                                                                                            </div>
                                                                                                        ))}
                                                                                                    </div>
                                                                                                    {inspectionImageList?.length > 1 &&<>
                                                                                                        <button className="scroll-button right" onClick={()=> scrollRight(scrollRef)}><FontAwesomeIcon icon={faChevronRight} /></button>
                                                                                                    </>}
                                                                                                </div>
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                </>}
                                                                                
                                                                                <div className="card cs-card p-2">
                                                                                    <div className="p-2 me-2">
                                                                                        <span> Don’t worry! We’ll account for this in the home’s offer price. If you have more questions about the repairs, just ask me in the chat box below.</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }
                                                                {/* Inspection damage question End */}

                                                                {ques?.question_type === "quality-specific-area" &&
                                                                    <>
                                                                        <div className="message-holder sub-card ml-85 mr-85 mt-3">
                                                                            <div className="message-bubble w-90 bg-none p-0 ps-relative">
                                                                                <div className="d-flex flex-row flex-wrap">
                                                                                    {ques?.response_options?.length > 0 && (
                                                                                        <>
                                                                                            {ques.response_options.map((option) => (
                                                                                                <>
                                                                                                    <div className={`custom-rest-box restb-options-highlight me-2 mt-2 ${taggedOption === option?.option_text ? 'custom_active' : ''}`} onClick={() => getTaggedImagesIds(option)}>
                                                                                                        <img src={`${process.env.PUBLIC_URL}/images/specific-area/${option?.icon_id}.png`} alt={option?.option_text} className="static-icons" />

                                                                                                        {option?.option_text}

                                                                                                    </div>
                                                                                                </>
                                                                                            ))}
                                                                                        </>
                                                                                    )}
                                                                                </div>

                                                                                {showImage && <>
                                                                                    <div className="d-flex flex-row flex-wrap mt-5 justify-content-center">
                                                                                        {taggedImgList?.length > 0 &&
                                                                                            <>
                                                                                                <div className="me-2 mb-2 mw-10em font-size-lg line-height score-highlights p-2">
                                                                                                    <span><b>Quality Score:</b> {removeHtmlTags(toolTipScore)}</span>
                                                                                                </div>
                                                                                                <div id={`carouselQualitySpecific${ques?.question_id}`} className="carousel slide mt-2" data-bs-ride="carousel">
                                                                                                    {taggedImgList?.length > 1 && <>
                                                                                                        <div className="carousel-indicators">
                                                                                                            {taggedImgList.map((option, index) => (
                                                                                                                <button key={index} type="button" data-bs-target={`#carouselQualitySpecific${ques?.question_id}`} data-bs-slide-to={index} className={index === 0 ? 'active' : ''} aria-current={index === 0 ? 'true' : undefined} aria-label={`Slide ${index + 1}`} ></button>
                                                                                                            ))}
                                                                                                        </div>
                                                                                                    </>}

                                                                                                    <div className="carousel-inner">
                                                                                                        {taggedImgList.map((tag, opIndex) => (
                                                                                                            <>
                                                                                                                <div key={opIndex} className={`carousel-item ${opIndex === 0 ? 'active' : ''}`}>
                                                                                                                    {tag?.image !== '' &&
                                                                                                                        <>
                                                                                                                            <img src={`data:image/jpeg;base64,${tag?.image}`}  onClick={() => openModal(0, taggedImgList, tag?.image_id)} className="cursor-pointer rounded-2" width="640" height="340" alt={`Slide ${opIndex + 1}`} />
                                                                                                                        </>
                                                                                                                    }
                                                                                                                    {(!tag?.image || tag?.image === '' || taggedImgList?.length === 0) &&
                                                                                                                        <>
                                                                                                                            <div className="gray-image-bg">
                                                                                                                                <div className="text-center">
                                                                                                                                    {tag?.apiResponse && <div>{tag?.apiResponse}</div>}

                                                                                                                                    {!tag?.apiResponse && <ChatLoader />}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </>
                                                                                                        ))}
                                                                                                    </div>


                                                                                                    {taggedImgList?.length > 1 && <>
                                                                                                        <button className="carousel-control-prev" type="button" data-bs-target={`#carouselQualitySpecific${ques?.question_id}`} data-bs-slide="prev">
                                                                                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                                                            <span className="visually-hidden">Previous</span>
                                                                                                        </button>
                                                                                                        <button className="carousel-control-next" type="button" data-bs-target={`#carouselQualitySpecific${ques?.question_id}`} data-bs-slide="next">
                                                                                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                                                                            <span className="visually-hidden">Next</span>
                                                                                                        </button>
                                                                                                    </>}
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                        {taggedImgList?.length === 0 && <>
                                                                                            <div className="gray-image-bg">
                                                                                                <div className="text-center">
                                                                                                    No Image Available
                                                                                                </div>
                                                                                            </div>
                                                                                        </>

                                                                                        }
                                                                                    </div>
                                                                                </>}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }

                                                                {ques?.question_type === "quality-score" && ques?.response_options.some(option => option?.isChecked === true && option?.isShow === true) &&
                                                                    <>
                                                                        <div className={`message-holder sub-card ml-85 mr-85 rounded-2 p-2 mt-3 ${showQualityAnimation === true ? 'bg-green' : ''}`}>
                                                                            <div className="message-bubble bg-none p-0 ps-relative">
                                                                                {showQualityAnimation === true &&
                                                                                    <>
                                                                                        <div className="loader-text text-center p-2">
                                                                                            <h6>Awesome Work!</h6>
                                                                                            <h6>Hold on while I fetch the overall quality score of the property for you, based on what we have so far.</h6>
                                                                                            <img src={homeChatGif} width="80" alt="" />
                                                                                        </div>
                                                                                    </>
                                                                                }

                                                                                {showQualityAnimation === false &&
                                                                                    <>
                                                                                        <div className="card cs-card p-2">
                                                                                            <div className="card-body">
                                                                                                <div className="mt-2 d-flex font-size-sm">
                                                                                                    <FontAwesomeIcon icon={faInfoCircle} className="me-2 font-size-xxl" />
                                                                                                    <span>
                                                                                                        Our scoring model is based on Fannie Mae and Freddie Mac guidelines. It reflects three key aspects: the property’s condition, quality, and potential. This means you’ll know right away if a place needs some love or if it’s move-in ready and waiting for you.
                                                                                                    </span>
                                                                                                </div>
                                                                                                <span className="mt-4 font-size-sm line-height fw-bold">
                                                                                                    Here’s the score for this property!
                                                                                                </span>


                                                                                                {ques?.qualityScore && <>
                                                                                                    <div className="me-2 mt-2 mw-10em font-size-lg line-height score-highlights p-2">
                                                                                                        <img src={`${process.env.PUBLIC_URL}/images/quality-icons/${ques?.qualityScore}.png`} alt="Icon" className="static-icons me-2" /> <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ques?.score_text) }}></span>
                                                                                                    </div>
                                                                                                </>}

                                                                                                {ques?.qualityScore === 5 && <>
                                                                                                    <div className="me-2 mt-2 mw-10em font-size-lg line-height">
                                                                                                        No worries! This could be a good investment opportunity if you're up for it. I’ll make sure the offer price matches the work needed.
                                                                                                    </div>
                                                                                                </>}

                                                                                                <div className="mt-2 mw-10em rounded-1">
                                                                                                    <img onClick={() => openModal(1, ques?.qualityScore, '')} src={`${process.env.PUBLIC_URL}/images/quality-score/${ques?.qualityScore}.png`} alt="" className="cursor-pointer custom-rest-img rounded-2 bg-green mt-1" />
                                                                                                </div>

                                                                                                <div className="mt-4 d-flex font-size-sm">
                                                                                                    <span>
                                                                                                        <i><b>Note:</b>This is not the actual image of the subject property but a graphic image to represent the condition and quality score of the property.**</i>
                                                                                                    </span>
                                                                                                </div>



                                                                                                <div className="mt-4 mb-3 font-size-sm">
                                                                                                    <div className="accordion font-size-sm" id="qualityScoreBubble">
                                                                                                        <div className="accordion-item">
                                                                                                            <h2 className="accordion-header">
                                                                                                                <button className="accordion-button fw-bold font-size-sm custom-accordion-button p-2 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                                                                                    Here’s a breakdown of all the scores.
                                                                                                                </button>
                                                                                                            </h2>
                                                                                                            <div id="collapseOne" className="accordion-collapse collapse" data-bs-toggle="collapse" data-bs-parent="#qualityScoreBubble">
                                                                                                                <div className="accordion-body font-size-sm">
                                                                                                                    <div className="table-responsive">
                                                                                                                        <table className="table font-size-sm table-bordered table-hover mb-0">
                                                                                                                            <thead>
                                                                                                                                <tr className="bg-green">
                                                                                                                                    <th className="bg-green font-size-sm w-30" scope="col">Score</th>
                                                                                                                                    <th className="bg-green font-size-sm" scope="col">Summary</th>
                                                                                                                                    <th className="bg-green font-size-sm" scope="col">Materials & Finishes</th>
                                                                                                                                    <th className="bg-green font-size-sm" scope="col">Rooms</th>
                                                                                                                                </tr>
                                                                                                                            </thead>
                                                                                                                            <tbody>
                                                                                                                                <tr>
                                                                                                                                    <th className="bg-green font-size-sm" scope="row"><img src={`${process.env.PUBLIC_URL}/images/quality-icons/6.png`} alt="Icon" className="static-icons me-2" />Tear Down</th>
                                                                                                                                    <td className="font-size-sm">Requires <b>major repairs</b> and is unsafe or unlivable as-is.</td>
                                                                                                                                    <td className="font-size-sm">Basic, <b>outdated,</b> and damaged materials</td>
                                                                                                                                    <td className="font-size-sm"><b>Undersized rooms</b> with substandard dimensions.</td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <th className="bg-green font-size-sm" scope="row"><img src={`${process.env.PUBLIC_URL}/images/quality-icons/5.png`} alt="Icon" className="static-icons me-2" />Fixer-Upper</th>
                                                                                                                                    <td className="font-size-sm"><b>Needs maintenance</b> or component replacements to improve livability.</td>
                                                                                                                                    <td className="font-size-sm">Stock materials with <b>minimal upgrades</b></td>
                                                                                                                                    <td className="font-size-sm">Limited room size.</td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <th className="bg-green font-size-sm" scope="row"><img src={`${process.env.PUBLIC_URL}/images/quality-icons/4.png`} alt="Icon" className="static-icons me-2" />Entry Level</th>
                                                                                                                                    <td className="font-size-sm">Requires <b>minor maintenance</b> or updates but is generally functional.</td>
                                                                                                                                    <td className="font-size-sm"><b>Builder-grade finishes</b> with few upgrades</td>
                                                                                                                                    <td className="font-size-sm">Moderately sized rooms.</td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <th className="bg-green font-size-sm" scope="row"><img src={`${process.env.PUBLIC_URL}/images/quality-icons/3.png`} alt="Icon" className="static-icons me-2" />Premium</th>
                                                                                                                                    <td className="font-size-sm">Well-maintained with recent upgrades and <b>minimal wear</b>.</td>
                                                                                                                                    <td className="font-size-sm"><b>Above-average appliances</b> and finishes with some custom touches</td>
                                                                                                                                    <td className="font-size-sm">Ample room size.</td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <th className="bg-green font-size-sm" scope="row"><img src={`${process.env.PUBLIC_URL}/images/quality-icons/2.png`} alt="Icon" className="static-icons me-2" />Luxe</th>
                                                                                                                                    <td className="font-size-sm">In <b>excellent condition</b> with high-quality features and no repairs needed.</td>
                                                                                                                                    <td className="font-size-sm"><b>High-end materials</b> with many custom design elements</td>
                                                                                                                                    <td className="font-size-sm"><b>Large rooms,</b> often with high ceilings or unique designs.</td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <th className="bg-green font-size-sm" scope="row"><img src={`${process.env.PUBLIC_URL}/images/quality-icons/1.png`} alt="Icon" className="static-icons me-2" />Top-Tier</th>
                                                                                                                                    <td className="font-size-sm">Brand-new, <b>flawless condition</b> with luxury features.</td>
                                                                                                                                    <td className="font-size-sm"><b>Premium,</b> top-quality finishes and appliances</td>
                                                                                                                                    <td className="font-size-sm">Oversized rooms with ornate ceilings and <b>intricate details</b>.</td>
                                                                                                                                </tr>
                                                                                                                            </tbody>
                                                                                                                        </table>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </>
                                                        }

                                                        {!loading && showQualityAnimation === false && (index === (propertyMarketQuestions.length - 1)) && ques?.isLast === false && ques?.question_type !== "single-select" && ques?.question_type !== 'single-select-emoji' && (!ques?.isSaved || (ques?.question_type === "quality-score" && ques?.isSaved)) &&
                                                            <>
                                                                <div className="d-flex js-end mt-3">

                                                                    {ques?.question_type !== "quality-score" && <>
                                                                        <button id="next-question" data-test-id={`get-next-question${ques?.question_id}`} className={`button-secondary mw-10em ${loading ? 'disable' : ''}`} onClick={() => saveDefaultResponse(ques?.question_id)}>Next</button>
                                                                    </>}
                                                                    {ques?.question_type === "quality-score" && ques?.response_options.some(option => option?.isChecked === true && option?.isShow === true) && <>
                                                                        <button id="next-question" data-test-id={`get-next-question${ques?.question_id}`} className={`button-secondary mw-10em ${loading ? 'disable' : ''}`} onClick={() => saveDefaultResponse(ques?.question_id)}>Next</button>
                                                                    </>}
                                                                </div>
                                                            </>
                                                        }

                                                        {ques?.isLast === true &&
                                                            <>
                                                                {!ques?.response &&
                                                                    <>
                                                                        <div className="d-flex js-end mt-3">
                                                                            <button id="next-question" data-test-id={`get-next-question${ques?.question_id}`} className={`button-secondary mw-10em ${loading ? 'disable' : ''}`} onClick={() => saveDefaultResponse(ques?.question_id)}>Next</button>
                                                                        </div>
                                                                    </>
                                                                }
                                                                {ques?.response &&
                                                                    <>
                                                                        <ShowOffer index={index} pageName={pageName} dataFromParent={refreshOfferPrice} />
                                                                    </>
                                                                }
                                                            </>
                                                        }

                                                        {/* Questions end */}
                                                    </>
                                                }
                                                {(index === (propertyMarketQuestions.length - 1)) && !loading && propertyMarketQuestions?.length > 2 && <div className="mt-btm"></div>}
                                            </>
                                        ))}
                                    </>
                                }

                                {/* If chat not loaded yet */}
                                {(loading || propertyMarketQuestions?.length < 2) &&
                                    <div className="message-holder mira-chat ml-85 mr-85">
                                        <div className="message-bubble">
                                            <div className={`label ${propertyMarketQuestions?.length === 0 ? 'd-flex fw-bold' : 'd-none'}`}>
                                                Agent Mira
                                            </div>

                                            <div className="message-text d-flex js-start">
                                                <ChatLoader />
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/* <div ref={bottomRef} /> */}
                            </div>
                        </div>

                        <div className="fixed-button mt-3">
                            <ChatInput userInput={userInput} handleInputChange={handleInputChange} handleKeyDown={handleKeyDown} getChat={getChat} getChatSuggestions={getChatSuggestions} loading={loading} pageName={pageName} questions={propertyMarketQuestions} />
                        </div>
                    </div>
                </div>

                <div className="part left-section">
                    <StickyCard />
                </div>
            </div>


            {!loading &&
                <>
                    {(Object.keys(cmaDataList)?.length === 0) && <>
                        <div className="d-flex js-end ps-sticky gap-20 mt-5">
                            <button
                                className="button-primary mw-10em"
                                onClick={() => navigate(`/`)}
                                data-test-id="back-to-home-button"
                            >
                                Back to Home
                            </button>
                        </div>
                    </>}
                </>
            }

            {/* Modal for Full-Image View */}
            {isModalOpen && (
                <div className="standout-modal-overlay" onClick={closeModal}>
                    <div className="standout-modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="standout-close-button z-index-top" onClick={closeModal}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>

                        {Array.isArray(selectedImage) ? (
                            <>
                                <div id="carouselFullView" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                        {selectedImage.map((imgList, opIndex) => (
                                            <>
                                                <div key={opIndex} className={`carousel-item ${imgList?.isActive ? 'active' : ''}`}>
                                                    <img src={`data:image/jpeg;base64,${imgList?.image}`} className="rounded-2 max-h-100" alt={`Slide ${opIndex + 1}`} />
                                                </div>
                                            </>
                                        ))}
                                    </div>

                                    {selectedImage?.length > 1 && <>
                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselFullView" data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselFullView" data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </>}
                                </div>
                            </>
                        ) : (
                            <img src={selectedImage} alt="Full view" className="full-image" />
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default MarketChat;
