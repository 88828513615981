import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { sendOfferEmail } from '../services/apiService';
import { offerDownload } from '../services/apiService';
import { useSelector } from 'react-redux';
import { renderStars } from '../utils/Common';
import { getCma } from '../../slices/preferenceSlice';
import { getImagesList } from '../../slices/propertySlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../utils/Tooltip';
import Header from "../header/header";
import '../../assets/css/offer.css';
import Sidebar from "../property/sidebar/sidebar";
import Modal from '../utils/Modal';
import { toast } from 'react-toastify';
import { LoaderContext } from '../services/LoaderContext';
import NoComparable from "../services/no-comparable";
import { rangeInfo } from '../../slices/marketSlice';
import DownloadPdfFile from "./download-pdf";
import scheduleImg from '../../assets/images/schedule.png'
import deadlineImg from '../../assets/images/deadline.png'
import downPaymentImg from '../../assets/images/down-payment.png'
import receiptImg from '../../assets/images/receipt.png'
import depositImg from '../../assets/images/deposit.png'
import houseImg from '../../assets/images/house.png'
import dynamicsImg from '../../assets/images/dynamics.png'
import needsImg from '../../assets/images/needs.png'
import inspection from '../../assets/images/checked.png'
import financing from '../../assets/images/saving.png'
import appraisal from '../../assets/images/performance-award.png'
import sale from '../../assets/images/acquisition.png'
import title from '../../assets/images/testing.png'
import hoa from '../../assets/images/apartment.png'
import ThirdChart from "../property/charts/chart-three";
import ChatLoader from "../services/chat-loader";
import { propertyMarketCharts, getSuggestedOffer } from '../services/apiService';
import { scrollToBottom } from '../utils/Common';

const cs_width = "35%";
const pageName = "property_offer";

const Offer = () => {
    const navigate = useNavigate();
    const bottomRef = useRef(null);
    const hasFetchedData = useRef(false);
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const propertyId = useSelector((state) => state.propertySlice.propertyId);
    const listCma = useSelector(getCma);
    const imageList = useSelector(getImagesList);
    const rangeList = useSelector(rangeInfo);
    const [cityCounty, setClickdata] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showEmailSuccessModal, setEmailSuccessModal] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showdata, setShowData] = useState(false);
    const [emailInput, setEmailId] = useState('');
    const [imageSrc, setImageSrc] = useState(imageList);
    const [offerList, setOfferDetail] = useState({});
    const [chartObject, setChartObject] = useState({});

    const fetchOfferDetails = useCallback(async () => {
        showLoader();
        try {
            const response = await getSuggestedOffer(propertyId, pageName, { "is_history": false });
            if (response.code === 200) {
                const offerResponse = await offerDownload(propertyId);
                if (offerResponse.code === 200) {
                    const offerResponse = await offerDownload(propertyId);
                    setShowData(true);
                    setOfferDetail(offerResponse.response);
                }
            }
            hideLoader();
        } catch (error) { hideLoader(); }
    }, [showLoader, hideLoader, propertyId]);

    const getChartsListing = useCallback(async (payload) => {
        try {
            const chartResponse = await propertyMarketCharts(payload);
            if (chartResponse.code === 200) {
                setChartObject(chartResponse.response);
                hideLoader();
            }
        } catch (error) { }
    }, [hideLoader]);

    useEffect(() => {
        if (offerList) {
            setShowData(true);
        }

        if (rangeList) {
            setClickdata({ "city": rangeList?.city })
            getChartsListing({ "city": rangeList?.city });
        }

        if (!hasFetchedData.current) {
            fetchOfferDetails();
            hasFetchedData.current = true;
        }
    }, [showModal, rangeList, hasFetchedData, propertyId, offerList, fetchOfferDetails, getChartsListing]);

    useEffect(() => {
        if (imageList) {
            setImageSrc(imageList);
        }
        scrollToBottom(bottomRef);
    }, [imageList]);

    const handleInputChange = (event) => {
        event.preventDefault();
        setEmailId(event.target.value);
    };

    const validateEmail = (email) => {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const sendEmail = async () => {
        if (validateEmail(emailInput)) {
            showLoader();
            try {
                const payload = { "email": emailInput, "mls_id": propertyId, "city": rangeList.city, "category": "offer" }
                const response = await sendOfferEmail(payload);
                if (response.code === 200) {
                    checkModal();
                    checkEmailModal();
                    hideLoader();
                }
                else {
                    hideLoader();
                }
            }
            catch (error) {
                hideLoader();
            }
        }
        else {
            toast.warning("Please enter a valid email address.");
        }
    };

    const checkModal = () => {
        setShowModal((prevState) => !prevState);
    };

    const closeAnimatedPopup = () => {
        setShowPopup((prevState) => !prevState);
    };

    const checkEmailModal = () => {
        setEmailId('');
        setEmailSuccessModal((prevState) => !prevState);
    };

    const handleDataFromDownloadPdf = (data) => {
        if (data === true) {
            setShowPopup(true);
        }
    };

    return (
        <>
            <div className="d-flex page border-on flex-column">
                <Header />

                {listCma && (Object.keys(listCma)?.length > 0) ? (
                    <>
                        <Sidebar />
                        <div className="d-flex justify-content-center" ref={bottomRef}>
                            <div className="scrollable-content mb-5 w-100 flex-justify-center flex-content-center justify-content-center justify-items-center">
                                <div className="container d-flex justify-content-center">
                                    <div className="content mt-top">
                                        <div className="mb-0 p-2 max-w">
                                            <div className="d-flex mb-3">
                                                <div className="heres-your-offer">Here's your offer</div>
                                            </div>

                                            <div className="d-flex js-space gap-20">
                                                <div className="offer_parent">
                                                    {imageSrc ? (
                                                        <img loading="lazy" alt="" src={`data:image/jpeg;base64,${imageSrc}`} />
                                                    ) : (
                                                        <>
                                                            <div className=" bg-gray" />
                                                        </>
                                                    )}
                                                </div>

                                                <div className="pre_chat_listing_details_inner">
                                                    <div className="pre_chat_price_content_parent">
                                                        <div className="offer_price_content offer_border_light">
                                                            <div className="pre_chat_frame_parent7">
                                                                <div className="mrk_sw_wrapper">
                                                                    <b className="mrk_miami">{offerList?.property_info?.UnparsedAddress}</b>
                                                                </div>
                                                                <div className="pre_chat_list_price_parent">
                                                                    <div className="pre_chat_list_price">List Price</div>
                                                                    <div className="pre_chat_price_value">
                                                                        <b className="pre_chat_empty_price">{offerList?.property_info?.ListPrice}</b>
                                                                    </div>
                                                                </div>
                                                                <div className="pre_chat_property_details">
                                                                    <div className="pre_chat_detail_items">
                                                                        <img className="pre_chat_furnituredouble_bed_icon" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/market/furnituredouble-bed.svg`} />
                                                                        <div className="pre_chat_beds">{offerList?.property_info?.BedroomsTotal} Beds</div>
                                                                    </div>
                                                                    <div className="pre_chat_detail_items1">
                                                                        <img className="pre_chat_hotelsbath_bathroom_icon" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/market/hotelsbath-bathroom.svg`} />
                                                                        <div className="pre_chat_baths">{offerList?.property_info?.BathroomsFull} Baths</div>
                                                                    </div>
                                                                    <div className="pre_chat_detail_items2">
                                                                        <img className="pre_chat_hotelshotel_building_icon" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/market/hotelshotel-building.svg`} />
                                                                        <div className="pre_chat_sqft">{offerList?.property_info?.LivingArea}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="pre_chat_offer_content_wrapper min-height-sm">
                                                            <div className="pre_chat_offer_content">
                                                                <div className="pre_chat_frame_parent8">
                                                                    <div className="pre_chat_suggested_offer_group">
                                                                        <div className="pre_chat_suggested_offer3">Agent Mira's Estimate</div>
                                                                        <div className="pre_chat_container">
                                                                            <b className="pre_chat_b2">{offerList?.property_info?.suggestedOffer}</b>
                                                                            <Tooltip text="This is a dynamic price and it gets calculated based on your preference and market conditions">
                                                                                <FontAwesomeIcon icon={faInfoCircle} className="pre_chat_phinfo_fill_icon3" />
                                                                            </Tooltip>
                                                                        </div>
                                                                    </div>
                                                                    <div className="pre_chat_confidence_parent mt-2">
                                                                        <div className="pre_chat_confidence me-2">Confidence </div>
                                                                        <div className="pre_chat_frame_wrapper5">
                                                                            <div className="pre_chat_star_parent">
                                                                                {(!listCma?.rating || listCma?.rating === 0) ? renderStars(5) : renderStars(listCma?.rating)}
                                                                            </div>
                                                                        </div>
                                                                        <Tooltip text="It shows How much Agent Mira is confident about the offer price.">
                                                                            <FontAwesomeIcon icon={faInfoCircle} className="ms-3 pre_chat_phinfo_fill_icon3" />
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex w-100 justify-content-evenly mt-1">
                                                                    <DownloadPdfFile pageName="offer" onData={handleDataFromDownloadPdf} />

                                                                    <div id="email-report" data-test-id="email-report-to-user" className={`button-primary mw-10em ${!listCma ? 'disable' : ''}`} onClick={checkModal}>
                                                                        Email
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {(offerList !== null && offerList !== undefined && Object.keys(offerList).length > 0) ? <>
                                                {(showdata && Object.keys(offerList).length > 0 && offerList?.property_info?.UnparsedAddress) ?
                                                    <>

                                                        <div className="pre_chat_agent_content offer-border-radius p-2 mt-3">
                                                            <div className="pre_chat_inner_agent_content mw-fit">
                                                                <div>
                                                                    <div>
                                                                        <b ><h5><b>Offer Summary</b></h5></b>

                                                                        Based on our analysis, a competitive offer for {offerList?.property_info?.UnparsedAddress} is <b>{offerList?.property_info?.suggestedOffer}</b>, which is <b>{offerList?.property_info?.percent_diff_price}</b> the current list price.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="offer-text-box">
                                                            <b> <h5 className="mt-2 mb-2">
                                                                <b>Recommended offer structure</b>
                                                            </h5></b>
                                                            <div className="offer-structure">
                                                                <div className="offer-structure-base">
                                                                    <div className="offer-structure-card">
                                                                        <div className="offer-inner-content">
                                                                            Closing date
                                                                            <b className="mt-2">
                                                                                {offerList?.property_info?.closingDate}
                                                                            </b>
                                                                        </div>
                                                                        <div className="mgt-auto">
                                                                            <img src={scheduleImg} alt="Icon" className="offer-icons" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="offer-structure-base">
                                                                    <div className="offer-structure-card">
                                                                        <div className="offer-inner-content">
                                                                            Offer expiration date
                                                                            <b className="mt-2">
                                                                                {offerList?.property_info?.offerExpirationDate}
                                                                            </b>
                                                                        </div>
                                                                        <div className="mgt-auto">
                                                                            <img src={deadlineImg} alt="Icon" className="offer-icons" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="offer-structure-base">
                                                                    <div className="offer-structure-card">
                                                                        <div className="offer-inner-content">
                                                                            Downpayment
                                                                            <b className="mt-2">
                                                                                {offerList?.property_info?.Downpayment}
                                                                            </b>
                                                                        </div>
                                                                        <div className="mgt-auto">
                                                                            <img src={downPaymentImg} alt="Icon" className="offer-icons" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="offer-structure mt-3">

                                                                <div className="offer-structure-base">
                                                                    <div className="offer-structure-card">
                                                                        <div className="offer-inner-content">
                                                                            Earnest money deposit
                                                                            <b className="mt-2">
                                                                                {offerList?.property_info['Earnest money deposit']}
                                                                            </b>
                                                                        </div>
                                                                        <div className="mgt-auto">
                                                                            <img src={depositImg} alt="Icon" className="offer-icons" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="offer-structure-base">
                                                                    <div className="offer-structure-card">
                                                                        <div className="offer-inner-content">
                                                                            Seller concessions
                                                                            <b className="mt-2">
                                                                                Origination fee
                                                                            </b>
                                                                        </div>
                                                                        <div className="text-center mgt-auto">
                                                                            <img src={receiptImg} alt="Icon" className="offer-icons" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="offer-text-box">
                                                            <b> <h5 className="mt-2 mb-2">
                                                                <b>The recommended offer price is driven by:</b>
                                                            </h5></b>
                                                            <div className="offer-structure">
                                                                <div className="reccomended-structure">
                                                                    <img src={houseImg} alt="Icon" className="offer-icons mt-2 mb-2" />
                                                                    <table className="text-center structure-table mb-2">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="reccomended-tableData comp-subject-column">Home Condition</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr className="tableRow">
                                                                                <td className="reccomended-tableData">{offerList?.recommended_price["Home Condition"][0] || "-"}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="reccomended-tableData">{offerList?.recommended_price["Home Condition"][1] || "-"}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="reccomended-tableData">{offerList?.recommended_price["Home Condition"][2] || "-"}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className="reccomended-structure">
                                                                    <img src={dynamicsImg} alt="Icon" className="offer-icons mt-2 mb-2" />
                                                                    <table className="text-center structure-table mb-2">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="reccomended-tableData comp-subject-column">Market Dynamics</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr className="tableRow">
                                                                                <td className="reccomended-tableData">{offerList?.recommended_price["Market Dynamics"][0] || "-"}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="reccomended-tableData">{offerList?.recommended_price["Market Dynamics"][1] || "-"}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="reccomended-tableData">{offerList?.recommended_price["Market Dynamics"][2] || "-"}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className="reccomended-structure">
                                                                    <img src={needsImg} alt="Icon" className="offer-icons mt-2 mb-2" />
                                                                    <table className="text-center structure-table mb-2">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="reccomended-tableData comp-subject-column">Match with your needs</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr className="tableRow">
                                                                                <td className="reccomended-tableData">{offerList?.recommended_price["Match with your needs"][0] || "-"}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="reccomended-tableData">{offerList?.recommended_price["Match with your needs"][1] || "-"}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="reccomended-tableData">{offerList?.recommended_price["Match with your needs"][2] || "-"}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="offer-text-box">
                                                            {cityCounty?.city && <>
                                                                <h5 className="mt-2">
                                                                    <b>Median Price/SqFt</b>
                                                                </h5>
                                                                <div>

                                                                    <ThirdChart chartList={chartObject} />
                                                                </div>

                                                            </>}
                                                        </div>

                                                        <div className="offer-text-box">
                                                            <h5 className="mt-2 mb-2">
                                                                <b>What are some comparable sales in the area?</b>

                                                                {offerList?.comparables?.length > 0 && <>
                                                                    <div className="table-responsive">
                                                                        <table className="table table-bordered custom mt-2">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col"></th>
                                                                                    <th className="comp-subject-column">Subject property</th>
                                                                                    <th className="text-center" colSpan={offerList.comparables.length}>Comparable properties</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Address</td>
                                                                                    <td className="comp-subject-column">
                                                                                        {offerList?.property_info?.UnparsedAddress}
                                                                                    </td>
                                                                                    {offerList.comparables.map((item, index) => (
                                                                                        <td key={index} className="address-td">{item.UnparsedAddress}</td>
                                                                                    ))}
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Beds/Baths</td>
                                                                                    <td className="comp-subject-column">
                                                                                        {offerList?.property_info?.BedroomsTotal} beds/{offerList?.property_info?.BathroomsFull} baths
                                                                                    </td>
                                                                                    {offerList.comparables.map((item, index) => (
                                                                                        <td key={index}>{item.BedroomsTotal} beds/{item.BathroomsTotalDecimal} baths</td>
                                                                                    ))}
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>List Price</td>
                                                                                    <td className="comp-subject-column">
                                                                                        {offerList?.property_info?.ListPrice}
                                                                                    </td>
                                                                                    {offerList.comparables.map((item, index) => (
                                                                                        <td key={index}>{item.ListPrice.toLocaleString()}</td>
                                                                                    ))}
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>List Price/Sq Feet</td>
                                                                                    <td className="comp-subject-column">
                                                                                        {offerList?.property_info["ListPrice/SqFeet"]}
                                                                                    </td>
                                                                                    {offerList.comparables.map((item, index) => (
                                                                                        <td key={index}>{item["ListPrice/SqFeet"]}</td>
                                                                                    ))}
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Days on Market</td>
                                                                                    <td className="comp-subject-column">
                                                                                        {offerList?.property_info?.DaysOnMarket} days
                                                                                    </td>
                                                                                    {offerList.comparables.map((item, index) => (
                                                                                        <td key={index}>{item.DaysOnMarket} days</td>
                                                                                    ))}
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Sales Price</td>
                                                                                    <td className="comp-subject-column">
                                                                                        {offerList?.property_info?.ClosePrice}
                                                                                    </td>
                                                                                    {offerList.comparables.map((item, index) => (
                                                                                        <td key={index}>{item.ClosePrice ? `${item.ClosePrice.toLocaleString()}` : 'N/A'}</td>
                                                                                    ))}
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Sales Price/Sq Feet</td>
                                                                                    <td className="comp-subject-column">
                                                                                        {offerList?.property_info["ClosePrice/SqFeet"]}
                                                                                    </td>
                                                                                    {offerList.comparables.map((item, index) => (
                                                                                        <td key={index}>{item["ClosePrice/SqFeet"] ? item["ClosePrice/SqFeet"] : 'N/A'}</td>
                                                                                    ))}
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Sold On Date</td>
                                                                                    <td className="comp-subject-column">
                                                                                        {offerList?.property_info?.CloseDate}
                                                                                    </td>
                                                                                    {offerList.comparables.map((item, index) => (
                                                                                        <td key={index}>{item.CloseDate ? item.CloseDate : 'N/A'}</td>
                                                                                    ))}
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Living Area</td>
                                                                                    <td className="comp-subject-column">
                                                                                        {offerList?.property_info?.LivingArea}
                                                                                    </td>
                                                                                    {offerList.comparables.map((item, index) => (
                                                                                        <td key={index}>{item.LivingArea.toLocaleString()}</td>
                                                                                    ))}
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Lot Size</td>
                                                                                    <td className="comp-subject-column">
                                                                                        {offerList?.property_info?.LotSizeSquareFeet}
                                                                                    </td>
                                                                                    {offerList.comparables.map((item, index) => (
                                                                                        <td key={index}>{item.LotSizeSquareFeet.toLocaleString()}</td>
                                                                                    ))}
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Year Built</td>
                                                                                    <td className="comp-subject-column">
                                                                                        {offerList?.property_info?.YearBuilt}
                                                                                    </td>
                                                                                    {offerList.comparables.map((item, index) => (
                                                                                        <td key={index}>{item.YearBuilt}</td>
                                                                                    ))}
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </>}
                                                            </h5>

                                                        </div>

                                                        <div className="pre_chat_agent_content offer-border-radius p-2 mt-3">
                                                            <div className="pre_chat_inner_agent_content mw-fit">
                                                                <h5 className="mt-2 mb-2">
                                                                    <b>Common offer contingencies</b>
                                                                </h5>
                                                                Buying a home is a significant investment, and there are various safeguards to help protect your interests during the process. These safeguards are known as contingencies.
                                                                A contingency is a condition or action that must be met for the contract to become binding. If the contingency is not met, you can back out of the contract without penalty.

                                                                <table className="mt-2 table-border-spacing">
                                                                    <thead>
                                                                        <tr className="text-start">
                                                                            <th className="reccomended-tableData w-40">Contingencies</th>
                                                                            <th className="reccomended-tableData">Description</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="reccomended-tableData w-40">
                                                                                <img src={inspection} alt="Icon" className="offer-icons me-3" />
                                                                                Inspection Contingency
                                                                            </td>
                                                                            <td className="reccomended-tableData">An inspection contingency allows the buyer to have the home inspected by a professional within a certain period. If the inspection reveals significant issues with the property, the buyer can negotiate repairs, request a price reduction, or cancel the contract.  </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="reccomended-tableData w-40">
                                                                                <img src={financing} alt="Icon" className="offer-icons me-3" />
                                                                                Financing Contingency </td>
                                                                            <td className="reccomended-tableData">A financing contingency gives the buyer time to obtain a mortgage loan to purchase the home. If the buyer cannot secure financing, they can cancel the contract without losing their deposit.  </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="reccomended-tableData w-40">
                                                                                <img src={appraisal} alt="Icon" className="offer-icons me-3" />
                                                                                Appraisal Contingency </td>
                                                                            <td className="reccomended-tableData">An appraisal contingency requires the home to appraise at or above the purchase price. If the appraisal is lower than the agreed price, the buyer can renegotiate the price or walk away from the deal.  </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="reccomended-tableData w-40">
                                                                                <img src={sale} alt="Icon" className="offer-icons me-3" />
                                                                                Sale of Current Home Contingency</td>
                                                                            <td className="reccomended-tableData">A sale of current home contingency means the buyer must sell their current home before purchasing the new one. If the buyer's home doesn't sell, they can cancel the contract.  </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="reccomended-tableData w-40">
                                                                                <img src={title} alt="Icon" className="offer-icons me-3" />
                                                                                Title Contingency </td>
                                                                            <td className="reccomended-tableData">A title contingency allows the buyer to review the title report to ensure there are no legal issues, such as liens or disputes, with the property's ownership. If there are problems with the title, the buyer can cancel the contract.  </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="reccomended-tableData w-40">
                                                                                <img src={hoa} alt="Icon" className="offer-icons me-3" />
                                                                                Homeowners Association (HOA) Contingency </td>
                                                                            <td className="reccomended-tableData">An HOA contingency allows the buyer to review the homeowners association documents, rules, and fees. If the buyer is not satisfied with the HOA terms, they can cancel the contract.</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : <>
                                                        <div className="d-flex js-center w-100 mt-5 mb-5">
                                                            <ChatLoader />
                                                        </div></>
                                                }</>
                                                : <></>}

                                            <div className="message-holder ml-85 mt-5 mb-ml-0">
                                                <div className="card w-100">
                                                    <div className="card-body p-3 text-center">
                                                        Thank you and good luck on your journey to home ownership! We're here for you, if you need to come back and make any updates or price out a different home.
                                                        <div className="mt-4 mb-5 mb-width">
                                                        <button
                                                            className="button-secondary mw-10em"
                                                            onClick={() => navigate('/')}
                                                            data-test-id="home-button"
                                                        >
                                                            Home
                                                        </button>

                                                        <button
                                                            className="button-primary mw-10em mt-2"
                                                            onClick={() => navigate(`/${propertyId}/market`)}
                                                            data-test-id="back-button"
                                                        >
                                                            Back
                                                        </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {showPopup ?
                                                <div className="offer_done_popup">
                                                    <div className="popup_container flex-column justify-content-center align-center">
                                                        <img alt="" src={`${process.env.PUBLIC_URL}/images/market/group-1171275857.svg`} className="w-50" />
                                                        <div className="heres-your-offer">
                                                            Great job!
                                                        </div>
                                                        <section>
                                                            <img className="z-top  clap_one" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/icons/clap.gif`} />
                                                            <img className="z-top  clap_one1" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/icons/clap.gif`} />
                                                            <img className="z-top  clap_one2" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/icons/clap.gif`} />
                                                        </section>
                                                        <p>
                                                            Congratulations on being so well-prepared to<br />
                                                            make an offer and start putting down roots!
                                                        </p>
                                                        <div className="d-flex w-100 justify-content-evenly">
                                                            <button
                                                                className="ssx_learn-more-wrapper justify-content-center w-min"
                                                                onClick={() => navigate("/")}
                                                                data-test-id="go-to-home-button"
                                                            >
                                                                <div className="ssx_learn-more1 text-center">Go to home</div>
                                                            </button>

                                                            <button
                                                                className="button-primary mw-10em w-min"
                                                                onClick={closeAnimatedPopup}
                                                                data-test-id="back-to-offer-button"
                                                            >
                                                                <div className="ssx_get-started text-center">Back to offer</div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                : <></>}

                                            {showEmailSuccessModal ?
                                                <div className="offer_done_popup">
                                                    <div className="popup_container flex-column justify-content-center align-center">
                                                        <img alt="" src={`${process.env.PUBLIC_URL}/images/market/group-1171275857.svg`} className="w-50" />
                                                        <div className="heres-your-offer">
                                                            Great job!
                                                        </div>
                                                        <p className="mt-2 mb-3">
                                                            We have received your request and are on it.
                                                            You should receive the Offer details in your inbox in a few minutes.
                                                        </p>
                                                        <div className="d-flex w-100 justify-content-evenly">
                                                            <button
                                                                className="button-primary mw-10em w-min"
                                                                onClick={checkEmailModal}
                                                                data-test-id="back-to-offer-button"
                                                            >
                                                                <div className="ssx_get-started text-center">Back to offer</div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                : <></>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) :
                    <>
                        <div className="d-flex justify-content-center align-items-center align-from-top">
                            <div className="content">
                                <NoComparable isShow={true} />
                            </div>
                        </div>
                    </>
                }
            </div>

            <Modal show={showModal} onClose={checkModal} cswidth={cs_width}>
                <div className="mb-2">
                    <label htmlFor="userEmail" className="form-label">To get a copy of our Agent Mira's Estimate</label>
                    <input type="email" onChange={handleInputChange} value={emailInput} className="form-control" id="userEmail" placeholder="Enter your email.." />
                    <div className="d-flex js-center">
                        <div
                            className="button-primary mw-10em mt-5"
                            onClick={sendEmail}
                            data-test-id="submit-email-button"
                        >
                            Submit
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Offer;
