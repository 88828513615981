import React, { useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { renderStars } from '../utils/Common';
import { getCma, getOfferSuggested } from '../../slices/preferenceSlice';
import { getImagesList, propertyData } from '../../slices/propertySlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../utils/Tooltip';

const StickyCard = () => {
    const navigate = useNavigate();
    const propertyDetails = useSelector((state) => state.propertySlice.propertyDetails);
    const listCma = useSelector(getCma);
    const offerPrice = useSelector(getOfferSuggested);
    const showOffer = useSelector(propertyData);

    const propertyId = useSelector((state) => state.propertySlice.propertyId);
    const imageList = useSelector(getImagesList);
    const [imageSrc, setImageSrc] = useState(imageList);

    useEffect(() => {
        if (imageList) {
            setImageSrc(imageList);
        }
    }, [imageList]);

    return (
        <div className="sticky-card">
            <img src={`data:image/jpeg;base64,${imageSrc}`} alt={propertyId} className="card-image rounded-2" />
            <div className="card-description mb-3 mt-3">
                <div className="mrk_sw_wrapper">
                    <b className="mrk_miami">{propertyDetails?.propertyUnparsedAddress}</b>
                </div>
            </div>

            <div className="d-flex js-space-between">
                <div className="pre_chat_detail_items">
                    <img className="pre_chat_furnituredouble_bed_icon" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/market/furnituredouble-bed.svg`} />
                    <div className="pre_chat_beds">{propertyDetails?.propertyBeds} Beds</div>
                </div>
                <div className="pre_chat_detail_items1">
                    <img className="pre_chat_hotelsbath_bathroom_icon" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/market/hotelsbath-bathroom.svg`} />
                    <div className="pre_chat_baths">{propertyDetails?.propertyBaths} Baths</div>
                </div>
            </div>

            <div className="pre_chat_detail_items2 mt-2">
                <img className="pre_chat_hotelshotel_building_icon" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/market/hotelshotel-building.svg`} />
                <div className="pre_chat_sqft">{propertyDetails?.propertySqft}</div>
            </div>

            <div className="card-description mb-2 mt-3">
                List Price <b className="mrk_miami">
                    
                        {propertyDetails?.propertyListPrice}
                    
                    </b>
            </div>

            <div className="card-description mb-2 mt-3">
                Agent Mira's Estimate <b className="mrk_miami">
                    {offerPrice?.estimatePrice && <>
                        {offerPrice?.estimatePrice}
                        <Tooltip text="The optimized offer price is tailored to market conditions and your preferences">
                            <FontAwesomeIcon icon={faInfoCircle} className="ms-1" />
                        </Tooltip>
                    </>}</b>
            </div>

            <div className="d-flex js-space-between">
                <div className="mrk_confidence_parent">
                    <div className="mrk_confidence">Confidence</div>
                    <div className="mrk_star_container_wrapper">
                        <div className="mrk_star_container">
                            {!offerPrice?.estimatePrice ? renderStars(0) : renderStars(listCma?.rating && listCma?.rating !== 0 ? listCma.rating : 5)}
                        </div>
                    </div>
                    <Tooltip text="This score is calculated based on similarity to recent comparable sales">
                        <FontAwesomeIcon icon={faInfoCircle} className="ms-3 pre_chat_phinfo_fill_icon3" />
                    </Tooltip>
                </div>
            </div>

            <div className="d-flex js-space-between mt-3">
                <button id="offer-page-btn" data-test-id="go-to-offer" className={`button-primary w-100 ${(!showOffer?.showOffer || (Object.keys(listCma)?.length === 0) || !offerPrice?.estimatePrice) ? 'disable' : ''}`} onClick={() => navigate(`/${propertyId}/offer`)}>
                    Offer Details
                </button>
            </div>
        </div>
    );
};

export default StickyCard;
